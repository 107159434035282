import { useTranslation } from "react-i18next";
import { useDisplayDate } from "shared/lib/hooks";

import { Button } from "shared/components/Button";
import { SessionLine, Label, Value, IconContainer } from "../styled";
import MonitorIcon from "../assets/icons/monitor.svg?react";
import PhoneIcon from "../assets/icons/phone.svg?react";
import { useDeleteSessionMutation } from "modules/users";

const deviceTypes = {
  web: <MonitorIcon />,
  mobile: <PhoneIcon />,
  unknown: "",
};

export const Session = ({
  device: { updated_at = "", device_name = "", device_type = "", id },
}) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const [deleteSession] = useDeleteSessionMutation();

  return (
    <SessionLine>
      <div>
        <IconContainer>{deviceTypes[device_type]}</IconContainer>
        <div>
          <Value>{device_name}</Value>
          <Label>Last {displayDate(updated_at, true)}</Label>
        </div>
      </div>
      <Button
        variant="secondary"
        onClick={() => {
          deleteSession(id);
        }}
      >
        {t("profile.logoutFromDevice")}
      </Button>
    </SessionLine>
  );
};
