import styled from "styled-components";
import { useDonutChartContext } from "../DonutChart";
import { useTranslation } from "react-i18next";

interface DonutChartLabelProps {
  /** Label and value to display when no slice is active. Uses "Total" if undefined. */
  defaultContents?: {
    label: string;
    value: string;
  };
}

/** Active slice label in the center of the Donut Chart */
export const DonutChartLabel = ({ defaultContents }: DonutChartLabelProps) => {
  const { isLoading, total, valueFormatter, active, sliceConfigs } =
    useDonutChartContext();
  const { t } = useTranslation();

  if (isLoading) {
    return null;
  }

  if (active) {
    const config = sliceConfigs[active.key];
    return (
      <LabelContainer>
        <ChartName>{config?.label ?? active.key}</ChartName>
        <ChartTotal>{valueFormatter(active.value)}</ChartTotal>
      </LabelContainer>
    );
  }

  return (
    <LabelContainer>
      <ChartName>{defaultContents?.label ?? t("reporting.total")}</ChartName>
      <ChartTotal>{defaultContents?.value ?? valueFormatter(total)}</ChartTotal>
    </LabelContainer>
  );
};

const LabelContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* This helps prevent the text from overlapping the Pie segments */
  max-height: 50%;
  max-width: 50%;
  aspect-ratio: 1;

  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  color: var(--color_dark_blue);
`;

const ChartName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 14px;
  font-weight: 600;
`;

const ChartTotal = styled.span`
  color: var(--color_dark_blue);
  font-size: 32px;
  font-weight: 400;
  line-height: 1;
`;
