/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, cloneElement, useMemo, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";

import {
  SelectStyles,
  ThemeStyles,
  ErrorMessage,
  OptionCreate,
} from "./SelectDropdownStyled";

import { Control } from "./Control";
import { ClearIndicator } from "./ClearIndicator";
import { Option, NoOptionsMessage } from "./Option";
import { useDebounce } from "shared/lib/hooks";
import {
  GroupBase,
  MenuPlacement,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
} from "react-select";

export type OptionType = {
  label: string;
  value: unknown;
};

interface SelectCreatableProps {
  options: OptionsOrGroups<OptionType, GroupBase<OptionType>>;
  setValue: (
    name: string,
    value: MultiValue<OptionType> | SingleValue<OptionType>
  ) => void;
  value?: OptionType;
  isMulti?: boolean;
  name: string;
  errorMessage?: string | null;
  menuPlacement?: MenuPlacement;
  noOptionsMessage?: string;
  onBlur?: (value: unknown) => void;
  optionCreateModal?: React.ReactElement;
  onSearch?: (searchTerm: string) => void;
  onTouch?: (name: string) => void;
  placeholder?: string;
  maxDisplayedOptions?: number;
}

export const SelectCreatable = ({
  options,
  setValue,
  value,
  isMulti = false,
  name,
  errorMessage = null,
  menuPlacement = "auto",
  noOptionsMessage,
  onBlur = () => {},
  optionCreateModal,
  onSearch = () => {},
  onTouch = () => {},
  placeholder,
  maxDisplayedOptions = 500, // Displaying too many options causes performance issues due to frequent re-renders in the CreatableSelect component
}: SelectCreatableProps) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<boolean | string>(false);

  const onSelectBlur = (value: unknown) => {
    onBlur(value);
    onSearch("");
    onTouch(name);
  };

  const showError = useMemo(() => {
    return (
      errorMessage && (isMulti && Array.isArray(value) ? !value.length : value)
    );
  }, [errorMessage, isMulti, value]);

  const onCreate = (option: OptionType) => {
    setValue(
      name,
      isMulti && Array.isArray(value)
        ? ([...value, option] as OptionType[])
        : option
    );
  };

  const createFormValues = useMemo(() => ({ name: modal }), [modal]);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearch = useDebounce(searchTerm);

  useEffect(() => {
    onSearch(debouncedSearch);
  }, [debouncedSearch, onSearch]);

  return (
    <>
      <CreatableSelect
        options={options?.slice(0, maxDisplayedOptions)}
        value={value}
        isClearable
        isSearchable
        closeMenuOnSelect={!isMulti}
        isMulti={isMulti}
        placeholder={placeholder ?? t("startTyping")}
        components={{
          IndicatorSeparator: null,
          Control,
          ClearIndicator,
          Option,
          NoOptionsMessage,
        }}
        styles={SelectStyles}
        onChange={(value) => setValue(name, value)}
        hideSelectedOptions={false}
        menuPlacement={menuPlacement}
        noOptionsMessage={() => noOptionsMessage}
        theme={ThemeStyles}
        onBlur={onSelectBlur}
        formatCreateLabel={(label) => (
          <OptionCreate>{t("createOption", { name: label })}</OptionCreate>
        )}
        onCreateOption={(option) => setModal(option)}
        onInputChange={(value) => {
          setSearchTerm(value);
        }}
        filterOption={null}
      />
      {showError && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {optionCreateModal &&
        cloneElement(optionCreateModal, {
          isShow: Boolean(modal),
          defaultValues: createFormValues,
          onClose: () => setModal(false),
          onCreate,
        })}
    </>
  );
};
