import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ChangeStatusEditor,
  getTrackingFormatForService,
  updateStatusValidationSchema,
  useGetDowntimeHistoryListQuery,
} from "modules/downtimeTracking";
import { useUpdateAssetMutation } from "modules/assets";
import OnlineIcon from "modules/assets/assets/online.svg?react";
import OfflineIcon from "modules/assets/assets/offline.svg?react";
import NotTrackingIcon from "modules/assets/assets/not-tracking.svg?react";

import { ConfirmationDialog } from "shared/components/Dialog";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { H2, H6, Hint } from "shared/components/Typography";
import { useForm } from "shared/lib/hooks/useForm";
import { Button } from "shared/components/Button";
import ThinAddIcon from "shared/assets/icon/thin-add.svg?react";

import { usePermission } from "app/providers/PermissionsProvider";

import {
  Box,
  Container,
  StyledButton,
  ManualStatusButton,
  StatusContainer,
  ReliabilityHeader,
} from "./StatusDetails.styled";
import { DowntimeHistoryList } from "../DowntimeHistoryList";
import { ReliabilityHistoryChart } from "modules/downtimeTracking/components/ReliabilityHistoryChart";
import { defaultReportPeriod } from "modules/reporting/lib/reportPeriodOptions";
import { ReliabilityHistoryPeriodSelect } from "modules/downtimeTracking/components/ReliabilityHistoryChart/ReliabilityHistoryPeriodSelect";
import { usePersistentState } from "modules/reporting/lib/usePersistentState";
import { useFlags } from "launchdarkly-react-client-sdk";

const StatusMap = [
  {
    value: "online",
    icon: <OnlineIcon />,
    color: "light_freshman",
    borderColor: "status_lime_border",
  },
  {
    value: "offline",
    icon: <OfflineIcon />,
    color: "priority_background_red",
    borderColor: "priority_red",
  },
  {
    value: "not_tracking",
    icon: <NotTrackingIcon />,
    color: "priority_background_grey",
    borderColor: "grey400",
  },
];

export const StatusDetails = ({
  currentStatus,
  name,
  id,
  assetCreationDate,
}) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const [newStatus, setNewStatus] = useState(null);
  const [manualStatus, setManualStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { enableDowntimeChart } = useFlags();

  const [reportPeriod, setReportPeriod] = usePersistentState(
    "reliability_chart_period",
    defaultReportPeriod
  );

  const [updateAsset] = useUpdateAssetMutation();

  const params = {
    id,
    targetType: "asset",
    "filter[g][0][m]": "or",
    "filter[g][0][ended_at_gt]": reportPeriod.startDate,
    "filter[g][0][ended_at_blank]": true,
  };
  const { data: historyData, refetch: refetchHistory } =
    useGetDowntimeHistoryListQuery(
      {
        ...params,
        page: currentPage,
        per_page: 10,
      },
      { refetchOnMountOrArgChange: true }
    );
  const { data: chartData, refetch: refetchChartData } =
    useGetDowntimeHistoryListQuery(
      {
        ...params,
        per_page: 10000,
      },
      { skip: !enableDowntimeChart }
    );

  const { assetsStatusesAddDowntimePermit, assetsViewDowntimeHistoryPermit } =
    usePermission();

  const onClose = () => {
    setNewStatus(null);
    setManualStatus(null);
    setCurrentPage(1);
    form.resetForm();
  };

  useEffect(() => {
    if (historyData) {
      setPaginationData(historyData.meta.pagination);
    }
  }, [historyData]);

  const isManualType = !!manualStatus;

  const form = useForm({
    initialValues: {
      status: manualStatus || newStatus,
      downtime_type: "unplanned",
      note: "",
      ...(isManualType ? { started_at: "", ended_at: "" } : {}),
    },
    validationSchema: updateStatusValidationSchema(t, false, isManualType),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = getTrackingFormatForService(values, isManualType);
      updateAsset({ id, body })
        .unwrap()
        .then(() => {
          refetchHistory();
          refetchChartData();
          onClose();
        })
        .catch(({ message }) => {
          addSnackBar({
            title: t("assets.failedUpdate"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: "Close",
          });
          onClose();
        });
    },
  });

  return (
    <Box>
      <Hint># {id}</Hint>
      <H2>{name}</H2>
      <StatusContainer>
        <Container>
          {StatusMap.map(({ value, icon, color, borderColor }) => (
            <StyledButton
              key={value}
              variant="secondary"
              icon={icon}
              color={color}
              borderColor={borderColor}
              isSelected={currentStatus === value}
              onClick={() => setNewStatus(value)}
              size="small"
            >
              {t(`assets.statuses.${value}`)}
            </StyledButton>
          ))}
        </Container>
        {assetsStatusesAddDowntimePermit && (
          <Button
            onClick={() => setManualStatus("offline")}
            variant="secondary"
          >
            <ManualStatusButton>
              <ThinAddIcon />
              <span> {t("assets.downtime_tracking.manualStatus")}</span>
            </ManualStatusButton>
          </Button>
        )}
      </StatusContainer>
      {newStatus && (
        <ConfirmationDialog
          title={t("assets.downtime_tracking.changeStatus")}
          confirmButtonLabel={t("save")}
          cancelButtonLabel={t("cancel")}
          disabledSubmit={!!Object.values(form.errors).length}
          description={<ChangeStatusEditor form={form} />}
          onProceed={form.handleSubmit}
          onCancel={onClose}
        />
      )}
      {manualStatus && (
        <ConfirmationDialog
          title={t("assets.downtime_tracking.addManualStatus")}
          confirmButtonLabel={t("save")}
          cancelButtonLabel={t("cancel")}
          disabledSubmit={!!Object.values(form.errors).length || !form.dirty}
          description={
            <ChangeStatusEditor
              form={form}
              type="manual"
              assetCreationDate={assetCreationDate}
            />
          }
          onProceed={form.handleSubmit}
          onCancel={onClose}
        />
      )}
      {assetsViewDowntimeHistoryPermit && (
        <>
          <ReliabilityHeader>
            <H6>{t("assets.reliability.title")}</H6>
            {enableDowntimeChart && (
              <ReliabilityHistoryPeriodSelect
                value={reportPeriod}
                onChange={setReportPeriod}
              />
            )}
          </ReliabilityHeader>
          {enableDowntimeChart && chartData?.data && (
            <ReliabilityHistoryChart
              startDate={reportPeriod.startDate}
              endDate={reportPeriod.endDate}
              data={chartData?.data}
            />
          )}
          <DowntimeHistoryList
            data={historyData}
            paginationData={paginationData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
    </Box>
  );
};
