import { default as Picker } from "react-datepicker";
import type { ReactDatePickerProps as PickerProps } from "react-datepicker";
import "./DatePickerBase.css";
import * as Styled from "./DatePickerBase.styled";
import { DatePickerHeader } from "../DatePickerHeader";
import { shift } from "@floating-ui/react";

export interface DatePickerBaseProps
  extends Omit<PickerProps<boolean>, "customInput" | "icon"> {
  dateFormat?: string;
  errorMessage?: string;
}

/**
 * Wrapper around react-datepicker, provides custom styling, header, icon, and error message.
 */
export const DatePickerBase = ({
  errorMessage,
  ...pickerProps
}: DatePickerBaseProps) => {
  const { minDate, maxDate } = pickerProps;

  return (
    <Styled.Wrapper>
      <Picker
        customInput={<Styled.Input isInvalid={!!errorMessage} />}
        renderCustomHeader={(props) => (
          <DatePickerHeader {...props} minDate={minDate} maxDate={maxDate} />
        )}
        icon={<Styled.Icon />}
        fixedHeight
        popperModifiers={[shift()]}
        {...(pickerProps as PickerProps)}
      />
      {errorMessage && <Styled.Error>{errorMessage}</Styled.Error>}
    </Styled.Wrapper>
  );
};
