import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionBarWrapper,
  ChartWrapper,
  ReportPageLayoutWrapper,
  SectionHeader,
  TableWrapper,
} from "./ReportPageLayout.styled";

interface ReportPageProps {
  kpis: ReactNode;
  chart: ReactNode;
  actionBar: ReactNode;
  table: ReactNode;
  reportKey: string;
}

export const ReportPageLayout = ({
  kpis,
  chart,
  actionBar,
  table,
  reportKey,
}: ReportPageProps) => {
  const { t } = useTranslation();
  return (
    <ReportPageLayoutWrapper>
      <div>
        <SectionHeader>{t("reporting.kpis")}</SectionHeader>
        {kpis}
      </div>
      <div>
        <SectionHeader>{t(`reporting.${reportKey}.title`)}</SectionHeader>
        <ChartWrapper>{chart}</ChartWrapper>
      </div>
      <ActionBarWrapper>{actionBar}</ActionBarWrapper>
      <TableWrapper>{table}</TableWrapper>
    </ReportPageLayoutWrapper>
  );
};
