import React from "react";
import { useTranslation } from "react-i18next";

import { useDisplayDate } from "shared/lib/hooks";

import CheckedCheckbox from "../../assets/icons/checkedCheckbox.svg?react";
import EmptyCheckbox from "../../assets/icons/emptyCheckbox.svg?react";
import SystemAvatar from "../../assets/icons/systemAvatar.svg?react";
import { getHistoryContent } from "../../lib/helpers/index";
import {
  Container,
  Name,
  Content,
  Box,
  NameContainer,
  Info,
  Image,
  Created,
  ContentView,
  ItemBox,
  ChecklistContainer,
  Title,
} from "./WorkOrderHistoryList.styled";
import { UserAvatar } from "shared/components/UserAvatar";
import { ShiftBy } from "shared/components/ShiftBy";

const View = ({ value = [], type }) => {
  return (
    <>
      {value?.length > 0 && Array.isArray(value) && (
        <>
          {value.map((val, index) => (
            <>
              <ItemBox type={type}>{val}</ItemBox>
              {index + 1 < value.length ? "," : ""}
            </>
          ))}
        </>
      )}
    </>
  );
};

export const WorkOrderHistoryList = ({ value = [] }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  return (
    <Container>
      {value.map((history) => {
        const { modifier, created_at, modifier_name } = history;

        const name =
          modifier_name === "System" ? "Scheduled event" : modifier?.full_name;

        const contents = getHistoryContent(history, t);

        return (
          <Box>
            {modifier ? (
              <ShiftBy y={4}>
                <UserAvatar user={modifier} size="md" />
              </ShiftBy>
            ) : (
              <Image>
                <SystemAvatar />
              </Image>
            )}
            <Info>
              <NameContainer>
                <Name>{name}</Name>
                <Content>
                  {contents.map((content) => {
                    return (
                      <>
                        {content.hasOwnProperty("value") ? (
                          <ContentView>
                            <Title>{content.key}</Title>
                            <ChecklistContainer checked={content.to === "true"}>
                              {content.to === "true" ? (
                                <CheckedCheckbox />
                              ) : (
                                <EmptyCheckbox />
                              )}
                              <div>{content.value}</div>
                            </ChecklistContainer>
                          </ContentView>
                        ) : (
                          <div>
                            <Title>{content.key}</Title>
                            <View value={content.from} type="from" />
                            <View value={content.to} type="to" />
                          </div>
                        )}
                      </>
                    );
                  })}
                </Content>
              </NameContainer>
              <Created>{displayDate(created_at, true)}</Created>
            </Info>
          </Box>
        );
      })}
    </Container>
  );
};
