import React from "react";
import { Box } from "./Sort.styled";
import ArrowUpIcon from "../../../../assets/icon/arrow-up.svg?react";

export const Sort = ({
  sortable,
  field,
  sort,
  onSortModelChange,
  initialSort = "",
}) => {
  const isActive = sort === "asc" || sort === "desc";

  const clickHandler = () => {
    if (onSortModelChange) {
      onSortModelChange(
        `${field}+${(sort || initialSort) === "desc" ? "asc" : "desc"}`
      );
    }
  };

  if (sortable !== true) {
    return null;
  }

  return (
    <Box
      upsideDown={isActive && sort === "desc"}
      onClick={clickHandler}
      hidden={!isActive}
    >
      <ArrowUpIcon />
    </Box>
  );
};
