import React, { useState, useRef, useMemo } from "react";
import { produce } from "immer";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Dropdown } from "shared/components/Dropdowns";
import { useOutsideClick, useFiltersParam } from "shared/lib/hooks";

import RemoveIcon from "shared/assets/icon/remove.svg?react";

import { FiltersComparedColumn } from "./FiltersComparedColumn";
import { FiltersQuick } from "./FiltersQuick";
import {
  FiltersMap,
  InitialFilter,
  OperatorsByType,
  FilterBooleanOperators,
} from "../configs/constants";

import {
  FilterContainer,
  Container,
  FilterHeader,
  FilterTitle,
  FilterCount,
  FilterBody,
  FilterBodyTitle,
  FilterResetButton,
  FilterAddButton,
  PickUpFilterWrapper,
  FilterFooter,
  FilterRow,
  FilterColumn,
  Label,
  FilterRemoveButton,
  FilterButton,
  FiltersHeader,
} from "./Filters.styled.js";
import { FastFilter } from "modules/filters/components/FastFilter";
import { ToggleFilters } from "modules/filters/components/ToggleFilters";
import { Button } from "shared/components/Button/index.js";

export const Filters = ({
  fields = [],
  entity = "assets",
  getOptions = () => {},
  getSearch = () => {},
  quickFilter,
  removeQuickFilter,
  filterCountValue = null,
  withFilter,
  showToggleFilters,
  initialFilters = [],
}) => {
  const { t } = useTranslation();
  const [showPopover, toggleShowPopover] = useState(false);
  const ref = useRef();
  const [filters, setFilters] = useFiltersParam(initialFilters ?? []);

  const filterCount =
    (filterCountValue || Object.keys(filters ?? {}).length) -
    (quickFilter ? 1 : 0);

  useOutsideClick(ref, toggleShowPopover);

  const fieldOptions = useMemo(
    () =>
      fields.reduce((fieldOptions, item) => {
        const { name, field, hidden, filter = true } = item;
        if (field === "parent" && quickFilter) {
          return fieldOptions;
        }
        if (!hidden && filter) {
          fieldOptions.push({ key: field, value: name });
        }
        return fieldOptions;
      }, []),

    [fields, quickFilter]
  );
  const fastFilters = useMemo(
    () => fields.filter((i) => i.fastFilter && !i.hidden),
    [fields]
  );

  return (
    <Container>
      <div>
        <FiltersHeader>
          {withFilter && (
            <span ref={ref}>
              <FilterButton
                type="button"
                onClick={() => toggleShowPopover(!showPopover)}
                isActive={showPopover}
              >
                <span>{t("filters.title")}</span>
                {filterCount > 0 && <FilterCount>{filterCount}</FilterCount>}
              </FilterButton>
              {showPopover && (
                <FiltersPopover
                  {...{
                    filters,
                    setFilters,
                    entity,
                    fieldOptions,
                    getOptions,
                    getSearch,
                  }}
                  onClearAll={() => {
                    setFilters([]);
                    toggleShowPopover(false);
                  }}
                  onCancel={() => toggleShowPopover(false)}
                  onApply={(updatedFilters) => {
                    setFilters(updatedFilters);
                    toggleShowPopover(false);
                  }}
                />
              )}
            </span>
          )}
          <FastFilters
            {...{
              fastFilters,
              filters,
              setFilters,
              entity,
              getOptions,
              getSearch,
            }}
          />
          {showToggleFilters && (
            <ToggleFilters
              entity={entity}
              activeFilters={filters}
              setFilters={setFilters}
            />
          )}
          {quickFilter && (
            <FiltersQuick
              quickFilter={quickFilter}
              removeQuickFilter={removeQuickFilter}
            />
          )}
        </FiltersHeader>
      </div>
    </Container>
  );
};

const FiltersPopover = ({
  filters,
  entity,
  fieldOptions,
  getOptions,
  getSearch,
  onClearAll,
  onCancel,
  onApply,
}) => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(filters);

  const onFieldChange = ({ index, value }) => {
    const { type } = FiltersMap[entity][value];
    const { defaultOperator, getDefaultValue } = OperatorsByType[type];

    setCurrent(
      produce(current, (draft) => {
        draft[index].field = value;
        draft[index].operator = defaultOperator;
        draft[index].value = getDefaultValue(defaultOperator);
      })
    );
  };

  const onOperatorChange = ({ index, value, type }) => {
    const { getDefaultValue } = OperatorsByType[type];

    setCurrent(
      produce(current, (draft) => {
        draft[index].operator = value;

        if (FilterBooleanOperators.includes(value)) {
          draft[index].value = 1;
        } else {
          draft[index].value = getDefaultValue(value);
        }
      })
    );
  };

  return (
    <FilterContainer>
      <FilterHeader>
        <FilterTitle>{t("filters.title")}</FilterTitle>
        {current.length > 0 && (
          <FilterResetButton type="button" onClick={onClearAll}>
            {t("filters.buttons.clearAll")}
          </FilterResetButton>
        )}
      </FilterHeader>
      <FilterBody>
        <FilterBodyTitle>{t(`filters.desc.${entity}`)}</FilterBodyTitle>
        {current.length === 0 && (
          <PickUpFilterWrapper>
            <Dropdown
              placeholder={t(`filters.buttons.pickOption`)}
              options={fieldOptions}
              setValue={(field) => {
                const { type } = FiltersMap[entity][field];
                const { defaultOperator, getDefaultValue } =
                  OperatorsByType[type];

                setCurrent(
                  produce(current, (draft) => {
                    draft.push({
                      field,
                      operator: defaultOperator,
                      value: getDefaultValue(defaultOperator),
                    });
                  })
                );
              }}
              withSearch={false}
              withClear={false}
            />
          </PickUpFilterWrapper>
        )}
        {current.map((filter, index) => {
          const type = FiltersMap[entity][filter.field]?.type;

          return (
            <FilterRow key={`${filter.field}-${index}`}>
              <Label htmlFor={`filters[${index}].field`}>
                {index === 0 ? t("filters.where") : t("filters.and")}
              </Label>
              <FilterColumn>
                <Dropdown
                  name={`filters[${index}].field`}
                  value={filter.field}
                  options={fieldOptions}
                  setValue={(_, value) =>
                    onFieldChange({
                      value,
                      index,
                    })
                  }
                  withSearch={false}
                  withClear={false}
                />
              </FilterColumn>
              <FilterColumn>
                {type && (
                  <Dropdown
                    name={`filters[${index}].operator`}
                    value={filter.operator}
                    options={OperatorsByType[type]?.options}
                    setValue={(_, value) =>
                      onOperatorChange({
                        index,
                        value,
                        type,
                      })
                    }
                    withSearch={false}
                    withClear={false}
                  />
                )}
              </FilterColumn>
              <FilterColumn width="50">
                {filter.operator && (
                  <FiltersComparedColumn
                    fieldType={type}
                    operatorType={filter.operator}
                    name={`filters[${index}].value`}
                    value={filter.value}
                    setValue={(_, value) => {
                      setCurrent(
                        produce(current, (draft) => {
                          draft[index].value = value;
                        })
                      );
                    }}
                    field={filter.field}
                    options={getOptions(filter.field)}
                    onSearch={getSearch(filter.field)}
                  />
                )}
              </FilterColumn>
              <FilterRemoveButton
                type="button"
                onClick={() => {
                  setCurrent(
                    produce(current, (draft) => {
                      draft.splice(index, 1);
                    })
                  );
                }}
              >
                <RemoveIcon />
              </FilterRemoveButton>
            </FilterRow>
          );
        })}
      </FilterBody>
      <FilterFooter>
        {current.length !== 0 && (
          <FilterAddButton
            type="button"
            onClick={() => {
              setCurrent(
                produce(current, (draft) => {
                  draft.push(InitialFilter);
                })
              );
            }}
          >
            {t("filters.buttons.addCondition")}
          </FilterAddButton>
        )}
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={() => onApply(current)}>Apply</Button>
      </FilterFooter>
    </FilterContainer>
  );
};

const FastFilters = ({
  fastFilters,
  filters,
  setFilters,
  entity,
  getOptions,
  getSearch,
}) => {
  return (
    <>
      {fastFilters.map((fastFilter) => {
        // If a filter that matches the FastFilter field already
        //  exists, the FastFilter shares that configuration
        const index = filters.findIndex(
          (i) => i.field === fastFilter.field && i.operator === "_in"
        );
        const activeFilter = filters[index];

        const { type } = FiltersMap[entity][fastFilter.field];
        if (type !== "enum" && type !== "select") {
          return null;
        }

        return (
          <FastFilter
            key={fastFilter.field}
            name={fastFilter.name}
            value={activeFilter?.operator === "_in" ? activeFilter.value : []}
            options={getOptions(fastFilter.field)}
            onSearch={
              type === "select" ? getSearch(fastFilter.field) : undefined
            }
            onReset={() => {
              setFilters(
                produce(filters, (draft) => {
                  draft.splice(index, 1);
                })
              );
            }}
            onChange={(value) => {
              if (value.length === 0) {
                // If no options are selected, remove the filter
                setFilters(
                  produce(filters, (draft) => {
                    draft.splice(index, 1);
                  })
                );
              } else if (index === -1) {
                // If the filter does not exist, add it
                setFilters(
                  produce(filters, (draft) => {
                    draft.push({
                      field: fastFilter.field,
                      operator: "_in",
                      value: value,
                    });
                  })
                );
              } else {
                // Otherwise, update the value
                setFilters(
                  produce(filters, (draft) => {
                    draft[index].value = value;
                  })
                );
              }
            }}
          />
        );
      })}
    </>
  );
};

Filters.propTypes = {
  name: PropTypes.string,
  fields: PropTypes.array,
  filterCount: PropTypes.number,
};
