import { useTranslation } from "react-i18next";
import { getUser } from "shared/components/Auth";

export interface LocaleSettings {
  language: string;
  currency: string;
  timezone: string;
  dateFormat: string;
}

/** Get localization information for the current user/entity */
export const useLocaleSettings = (): LocaleSettings => {
  const { i18n } = useTranslation();
  const user = getUser();

  return {
    language: i18n.language,
    currency: user.currency ?? "USD",
    timezone: user.timezone,
    dateFormat: user.dateFormat,
  };
};
