import styled, { css } from "styled-components";
import Arrow from "../../assets/arrow.svg?react";
import { Button } from "../Button";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  height: ${({ height }) => `${height}px`};
  background: ${({ current }) =>
    current ? "var(--color_primary_hover)" : "none"};
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: var(--color_text_color);
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  position: relative;

  // vertical line
  ${({ withVerticalLine }) =>
    withVerticalLine &&
    css`
      &:before {
        content: "";
        position: absolute;
        width: 2px;
        height: ${({ verticalLineHeight }) => `${verticalLineHeight}px`};
        left: ${({ level }) => (level > 0 ? "39px" : "7px")};
        top: ${({ childrenHeight }) => `${childrenHeight}px`};
        bottom: 0;
        background-color: var(--color_grey100);
      }
    `}

  // horizontal line
  ${(props) =>
    props.level > 0 &&
    css`
      padding-left: 32px;

      &:after {
        content: "";
        position: absolute;
        left: 8px;
        top: 16px;
        width: 9px;
        height: 2px;
        background-color: var(--color_grey100);
      }
    `}
`;

const ItemText = styled.span`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  padding: 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Text = styled(ItemText)`
  color: var(--color_text_color);
`;

export const HierarchyLink = styled(ItemText).attrs({ as: Link })`
  color: var(--color_primary_text);
  text-decoration: none;
`;

export const ArrowStyled = styled(Arrow)`
  transform: ${(props) => (props.$rotated ? "rotate(-90deg)" : "none")};
`;

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const AddButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledButton = styled(Button)`
  margin-top: 16px;
`;
