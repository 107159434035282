import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/Button";

import { Select } from "shared/components/Select";
import { Input } from "shared/components/Input";
import { IconButton } from "shared/components/IconButton";
import RemoveIcon from "shared/assets/icon/remove.svg?react";

export const VendorsInput = ({
  vendors = [],
  setValue,
  name,
  options = [],
  onSearch,
  onBlur,
  errors = [],
}) => {
  const { t } = useTranslation();

  const addVendor = () =>
    setValue(name, [
      ...vendors,
      {
        vendor: null,
        vendor_part_number: "",
        is_preferred: vendors.length === 0,
      },
    ]);

  const removeVendor = ({ index: indexToRemove }) => {
    const removedVendor = vendors[indexToRemove];
    const updatedVendors = [...vendors];
    updatedVendors.splice(indexToRemove, 1);
    if (updatedVendors.length > 0 && removedVendor.is_preferred) {
      const firstVendor = updatedVendors[0];
      updatedVendors.splice(0, 1, { ...firstVendor, is_preferred: true });
    }
    setValue(name, updatedVendors);
  };

  const setPreferred = ({ index: indexToUpdate }) => {
    let updatedVendors = vendors.map((value, index) => ({
      ...value,
      is_preferred: index === indexToUpdate,
    }));
    setValue(name, updatedVendors);
  };

  const updateVendor = ({ index: indexToUpdate, field, value }) => {
    const updatedVendors = [...vendors];

    const { vendor, vendor_part_number, is_preferred } = vendors[indexToUpdate];
    updatedVendors.splice(indexToUpdate, 1, {
      vendor,
      vendor_part_number,
      is_preferred,
      [field]: value,
    });

    setValue(name, updatedVendors);
  };

  return (
    <>
      {vendors.map(({ vendor_part_number, is_preferred, vendor }, index) => (
        <VendorContainer key={index}>
          <VendorPartNumber>
            <VendorSelectContainer>
              <Select
                options={options}
                onSearch={onSearch}
                onBlur={onBlur}
                value={vendor}
                setValue={(_, value) => {
                  updateVendor({ index, field: "vendor", value });
                }}
                placeholder={t("parts.form.vendorPlaceholder")}
              />
            </VendorSelectContainer>
            <Input
              // name={`vendors.[${index}].vendor_part_number`}
              value={vendor_part_number}
              onChange={({ target: { value } }) =>
                updateVendor({ index, field: "vendor_part_number", value })
              }
              onBlur={({ target: { value } }) =>
                updateVendor({ index, field: "vendor_part_number", value })
              }
              placeholder={t("parts.form.vendorPartNumber")}
              errorMessage={
                errors.length ? errors[index]?.vendor_part_number : ""
              }
            />
            <IconButton onClick={() => removeVendor({ index })}>
              <RemoveIcon />
            </IconButton>
          </VendorPartNumber>
          <VendorPreferredButton
            onClick={() => setPreferred({ index })}
            disabled={is_preferred}
          >
            <VendorPreferredIcon preferred={is_preferred} />
            {t("parts.form.preferred")}
          </VendorPreferredButton>
        </VendorContainer>
      ))}
      <Button onClick={addVendor} variant="secondary">
        {t("parts.form.addVendor")}
      </Button>
    </>
  );
};

const VendorPartNumber = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 8px;
`;

const VendorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const VendorSelectContainer = styled.div`
  width: 100%;
`;

const VendorPreferredIcon = styled.div`
  border-radius: 100px;
  background: white;
  width: ${({ preferred }) => (preferred ? `4px` : "14px")};
  height: ${({ preferred }) => (preferred ? `4px` : "14px")};
  border: ${({ preferred }) =>
    preferred ? "6px solid var(--color_corp_mint)" : "1px solid grey"};
`;

const VendorPreferredButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  color: black;
`;
