import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "shared/components/Button";

import SuccessIcon from "../../assets/icons/successIcon.svg?react";

export const SuccessfulBlock = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <SuccessIcon />
      <Title>{t("portal.successfulCreate")}</Title>
      <StyledButton onClick={onClick} variant="secondary">
        {t("portal.add")}
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 23px;
  line-height: 26.45px;
  color: var(--color_grey900);
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
`;
