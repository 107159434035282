import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";
import styled from "styled-components";

import { Details } from "widgets/Details";

import { WorkOrderPartList } from "modules/workOrderParts";
import {
  useUpdateTransactionMutation,
  useDeleteTransactionMutation,
} from "modules/parts";
import { useVisibleFields } from "modules/adminFieldsConfiguration";
import { FileManager } from "modules/fileManager";
import { WorkOrderChecklist } from "modules/workOrders";
import {
  useGetPMQuery,
  useAddPartTransactionMutation,
} from "modules/workOrders";

import { Switch } from "shared/components/Switch";
import { Priority } from "shared/components/Priority";
import { ViewModal } from "shared/components/Modals";
import { Description, H3, Hint } from "shared/components/Typography";
import {
  useDisplayDate,
  useDefaultTimePeriod,
  useTimePeriodOptions,
} from "shared/lib/hooks";
import { Divider } from "shared/components/Divider";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { getComingUpDate } from "../../lib/helpers";
import { getViewRows } from "../../lib/helpers/getViewRows";

import { useGetWorkOrdersQuery } from "modules/workOrders";
import { PMHeader } from "./PMHeader";

import { WOList, WOListChart } from "shared/components/WOList";
import { transformWOtoEvents } from "shared/lib/helpers/transformWOtoEvents";
import { Pagination } from "features/Pagination";
import { usePermission } from "app/providers/PermissionsProvider";
import { useSetModal } from "widgets/ModalController";
import { PeriodSelect } from "shared/components/PeriodSelect/index.js";
import {
  ViewHeader,
  PMInfo,
  Name,
  HeaderDividerStyled,
} from "../StyledSubComponents";

const fields = ["start_date", "progression", "time_to_complete"];

const workOrderFields = [
  "type",
  "schedule",
  "categories",
  "asset",
  "location",
  "vendors",
  "assign_to",
  "request",
  "requested_by",
];

export const PMView = ({
  isShow,
  onClose,
  onEdit,
  onClone,
  preventiveMaintenanceId,
}) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();
  const reportPeriodOptions = useTimePeriodOptions();
  const {
    pmsDetailsViewChecklistPermit,
    canDeletePmsSchedule,
    pmsDetailsExportPdfPermit,
    pmsDetailsChangeStatusPermit,
    pmsPartsViewPermit,
    canAddPartPms,
    canUpdatePartPms,
    canDeletePartPms,
    pmsHistoryPermit,
    pmsHistoryChartPermit,
    canEditPmsSchedule,
  } = usePermission();

  const setModal = useSetModal();

  const defaultTimePeriod = useDefaultTimePeriod();

  const [paginationData, setPaginationData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hideCompletedWO, setHideCompletedWO] = useState(false);
  const [currentHistoryTimePeriod, setCurrentHistoryTimePeriod] =
    useState(defaultTimePeriod);

  const { addSnackBar } = useSnackBar();

  const [addPartTransaction] = useAddPartTransactionMutation();
  const [updatePartTransaction] = useUpdateTransactionMutation();
  const [deletePartTransaction] = useDeleteTransactionMutation();

  const { currentData: PMData, error } = useGetPMQuery(
    preventiveMaintenanceId ?? skipToken
  );

  const { data: WOData } = useGetWorkOrdersQuery(
    {
      page: currentPage,
      per_page: 3,
      format_resource: "light",
      filter: {
        pm_schedule_id_eq: PMData?.id,
        created_at_gt: currentHistoryTimePeriod?.startDate,
        created_at_lt: currentHistoryTimePeriod?.endDate,
        ...(hideCompletedWO ? { work_order_status_id_not_eq: 5 } : {}),
      },
    },
    { skip: !PMData?.id }
  );

  const transformedWOData = transformWOtoEvents(WOData);

  useEffect(() => {
    if (WOData) {
      setPaginationData(WOData.meta.pagination);
    }
  }, [WOData]);

  const { isFieldVisible, areVisibleFieldsLoading } =
    useVisibleFields("pm_schedule");

  if (!preventiveMaintenanceId || !PMData || areVisibleFieldsLoading) {
    return null;
  }

  if (!PMData || error) {
    return <div>{t("preventive_maintenances.detailsError")}</div>;
  }

  const handleOnClose = () => {
    onClose();
    setCurrentPage(1);
    setHideCompletedWO(false);
  };

  const {
    template_work_order: {
      title: name,
      priority,
      description,
      images = [],
      documents = [],
      checklist,
      parts_transactions,
      asset,
      technicians,
      id: workOrderId,
      status: workOrderStatus,
    },
    id,
    updated_at,
    status,
    type,
    next_order_date,
    has_uncompleted_wo,
    has_work_orders,
    created_by_id,
    last_active_work_order_id,
  } = PMData;

  const files = images
    .map((file) => ({ ...file, isImage: true }))
    .concat(documents);

  const partsTransactions = parts_transactions.map((val) => ({
    ...val,
    part: { ...val.part, label: val.part.name, value: val.part.id },
  }));

  const rows = getViewRows(
    fields,
    workOrderFields,
    PMData,
    isFieldVisible,
    t,
    displayDate
  );

  const handlePartCreate = ({ part, quantity }) => {
    addPartTransaction({
      id: workOrderId,
      body: { quantity, part_id: part.value, type: "planned" },
    })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          content: JSON.stringify(message),
          type: "error",
        });
      });
  };

  const handlePartUpdate = ({ id, quantity }) => {
    updatePartTransaction({
      id,
      body: { quantity },
    })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          content: JSON.stringify(message),
          type: "error",
        });
      });
  };

  const handlePartDelete = (id) => {
    deletePartTransaction(id)
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          content: JSON.stringify(message),
          type: "error",
        });
      });
  };
  const headerPermissions = {
    pdf: pmsDetailsExportPdfPermit,
    delete: canDeletePmsSchedule(created_by_id, workOrderStatus),
    toggleStatus: pmsDetailsChangeStatusPermit,
    edit: canEditPmsSchedule(created_by_id, technicians, workOrderStatus),
  };

  const getComingUpValue = getComingUpDate(
    status,
    next_order_date,
    type,
    has_uncompleted_wo
  );

  const showWOChart = pmsHistoryChartPermit && has_work_orders;
  const showWOList =
    pmsHistoryPermit && (transformedWOData.data.length > 0 || hideCompletedWO);
  return (
    <ViewModal
      isOpen={isShow}
      onClose={handleOnClose}
      label={t("preventive_maintenances.name", { name })}
      subHeader={
        <PMHeader
          id={id}
          name={name}
          onClose={handleOnClose}
          onEdit={onEdit}
          onClone={onClone}
          status={status}
          type={type}
          hasUncompletedWo={has_uncompleted_wo}
          permissions={headerPermissions}
          lastActiveWOId={last_active_work_order_id}
        />
      }
    >
      <ViewHeader>
        <PMInfo>
          <Hint>
            #{id}
            <Next>
              {` ${t(`work_orders.schedule_type.${type}`)} ${
                getComingUpValue ? `- Next in ${getComingUpValue}` : ""
              }`}
            </Next>
          </Hint>
          {isFieldVisible("priority") && <Priority value={priority} />}
        </PMInfo>
        <Name>{name}</Name>
        {isFieldVisible("description") && (
          <Description>{description}</Description>
        )}
        <FileManager value={files} />
      </ViewHeader>
      <HeaderDividerStyled />
      <Details date={updated_at} rows={rows} />
      <DividerStyled />
      {checklist?.length > 0 && pmsDetailsViewChecklistPermit && (
        <WorkOrderChecklist value={checklist} withCheckbox={false} />
      )}
      {pmsPartsViewPermit && (
        <PartsContainer>
          <WorkOrderPartList
            onCreate={handlePartCreate}
            onUpdate={handlePartUpdate}
            onDelete={handlePartDelete}
            value={partsTransactions}
            assetId={asset?.id}
            titleFontSize="16px"
            module="PM"
            permissions={{
              add: canAddPartPms(created_by_id, technicians, workOrderStatus),
              edit: canUpdatePartPms(
                created_by_id,
                technicians,
                workOrderStatus
              ),
              delete: canDeletePartPms(
                created_by_id,
                technicians,
                workOrderStatus
              ),
            }}
          />
        </PartsContainer>
      )}
      <DividerStyled />
      {(showWOChart || showWOList) && (
        <Title>
          <H3>{t("work_orders.history")}</H3>
          <PeriodSelect
            value={currentHistoryTimePeriod}
            onChange={setCurrentHistoryTimePeriod}
            options={reportPeriodOptions}
          />
        </Title>
      )}
      {showWOChart && (
        <WOListChart
          id={id}
          currentTimePeriod={currentHistoryTimePeriod}
          attribute="pm_schedule_id_eq"
        />
      )}
      {showWOList && (
        <>
          <StyledSwitch
            onClick={() => setHideCompletedWO((prev) => !prev)}
            activated={hideCompletedWO}
          >
            {t(`work_orders.hideWorkOrders`)}
          </StyledSwitch>
          <WOList
            events={transformedWOData.data}
            onOpenWorkOrder={(id) => setModal({ type: "wo", id })}
            calendar={false}
            withOverdue
          />
          <DividerStyled />
          <Pagination
            paginationData={paginationData}
            label={t("work_orders.title_abb")}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </>
      )}
    </ViewModal>
  );
};

const Next = styled.span`
  display: inline;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

const DividerStyled = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const PartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 30px 0;
`;

const StyledSwitch = styled(Switch)`
  justify-content: end;
  margin: 8px 0;
  line-height: 20px;

  & span {
    font-weight: 400;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;
