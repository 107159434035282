import React from "react";
import PropTypes from "prop-types";
import { Modal } from "./Modal";
import { Button } from "shared/components/Button";
import {
  BodyWrapper,
  ActionBlock,
  ModalContentWrapper,
} from "./components/Modals.styled";
import { EditorHeader } from "./components/EditorHeader";
import { ScrollFadeBox } from "../ScrollFadeBox";

export const EditorModal = ({
  isOpen,
  label,
  onClose,
  onSubmit,
  disabledSubmit,
  isSubmitLoading,
  secondaryButtonLabel,
  primaryButtonLabel,
  children,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContentWrapper>
        <EditorHeader
          label={label ?? "Editor modal"}
          action={
            <ActionBlock>
              <Button onClick={onClose} variant="secondaryDark">
                {secondaryButtonLabel ?? "Cancel"}
              </Button>
              <Button
                onClick={onSubmit}
                disabled={disabledSubmit}
                isLoading={isSubmitLoading}
                variant="primaryDark"
              >
                {primaryButtonLabel ?? "Save"}
              </Button>
            </ActionBlock>
          }
        />
        <ScrollFadeBox disableTop>
          <BodyWrapper>{children}</BodyWrapper>
        </ScrollFadeBox>
      </ModalContentWrapper>
    </Modal>
  );
};

EditorModal.propTypes = {
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  disabledSubmit: PropTypes.bool,
  secondaryButtonLabel: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
};
