import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Switch } from "shared/components/Switch";
import { Row, Box, Td, Th, BodyContainer } from "./SettingsList.styled";

export const SettingsList = ({
  onSetVisible,
  onSetRequired,
  getIsChecked,
  getIsVisible,
  rows,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <BodyContainer>
        <Row>
          <Th scope="col" width="40%">
            {t("settings.field")}
          </Th>
          <Th scope="col" width="20%">
            {t("settings.visible")}
          </Th>
          <Th scope="col" width="40%">
            {t("settings.required")}
          </Th>
        </Row>
        {rows.map((row) => {
          const isRequiredFieldShow =
            row.field !== "parent" &&
            row.field !== "parts" &&
            row.field !== "path";

          return (
            <Row key={row.id}>
              <Td width="40%">{row["name"]}</Td>
              <Td width="20%">
                <Switch
                  onClick={() => onSetVisible(row.field)}
                  activated={getIsChecked(row.field, "visible")}
                />
              </Td>
              <Td width="40%">
                {isRequiredFieldShow && (
                  <Switch
                    onClick={() => onSetRequired(row.field)}
                    activated={getIsChecked(row.field, "required")}
                    disabled={getIsVisible(row.field)}
                  />
                )}
              </Td>
            </Row>
          );
        })}
      </BodyContainer>
    </Box>
  );
};

SettingsList.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      required: PropTypes.bool,
      visible: PropTypes.bool,
      name: PropTypes.string,
      field: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  onSetVisible: PropTypes.func,
  onSetRequired: PropTypes.func,
  getIsChecked: PropTypes.func,
  getIsVisible: PropTypes.func,
};
