import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useSignUpMutation } from "modules/auth";

import { useForm } from "shared/lib/hooks/useForm";

import { Button } from "shared/components/Button";
import { Input } from "shared/components/Input";
import {
  Box,
  DividerStyled,
  LabelStyled,
  Title,
  ActionBox,
  SignUpContainer,
  LinkStyled,
  Container,
  FormOptionsContainer,
} from "../styled";
import { SignUpInitialValues } from "modules/auth/config/constants";
import { signUpValidationSchema } from "modules/auth/config/validationSchema";
import { PasswordField } from "../PasswordField";
import { SignUpInvitation } from "../AdditionalPages/SignUpInvitation";
import { Header } from "../Header";
import { PrivacyPolicy } from "shared/components/PrivacyPolicy";
import { FormCheckbox } from "shared/components/FormCheckbox";

export const SignUpForm = () => {
  const [nextStep, setNextStep] = useState(false);
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();
  const [doSignUp] = useSignUpMutation();

  const onSubmit = async (body) => {
    submitHubSpotForm(body);

    doSignUp({ ...body, password_confirmation: body.password })
      .unwrap()
      .then(() => {
        setNextStep(true);
      })
      .catch(({ message }) => {
        if (message === "email: has already been taken") {
          form.setFieldError("email", t("m113", { ns: "errors" }));
        } else {
          addSnackBar({
            title: t("auth.snack.failCreationTitle"),
            content: message,
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        }
      });
  };

  const form = useForm({
    initialValues: SignUpInitialValues,
    validationSchema: signUpValidationSchema(t),
    onSubmit,
  });

  if (nextStep) {
    return <SignUpInvitation email={form?.values?.email} />;
  }

  return (
    <>
      <Header />
      <Container>
        <Box id="signUpForm">
          <Title>{t("auth.signUp.signUp")}</Title>
          <LabelStyled>{t("auth.signUp.firstNameLabel")}</LabelStyled>
          <Input
            type="text"
            name="name"
            placeholder={t("auth.signUp.firstNamePlaceholder")}
            value={form.values.name}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            errorMessage={form.touched.name && form.errors.name}
          />

          <LabelStyled>{t("auth.signUp.lastNameLabel")}</LabelStyled>
          <Input
            type="text"
            name="surname"
            placeholder={t("auth.signUp.lastNamePlaceholder")}
            value={form.values.surname}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            errorMessage={form.touched.surname && form.errors.surname}
          />

          <LabelStyled>{t("auth.signUp.companyLabel")}</LabelStyled>
          <Input
            type="text"
            name="company"
            placeholder={t("auth.signUp.companyPlaceholder")}
            value={form.values.company}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            errorMessage={form.touched.company && form.errors.company}
          />

          <LabelStyled>{t("auth.signUp.emailLabel")}</LabelStyled>
          <Input
            type="email"
            name="email"
            placeholder={t("auth.signUp.emailPlaceholder")}
            value={form.values.email}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            errorMessage={form.touched.email && form.errors.email}
          />

          <PasswordField form={form} variant={"password"} />

          <DividerStyled />
          <FormOptionsContainer>
            <FormCheckbox
              name="acceptCommunication"
              label={t("auth.signUp.acceptCommunication")}
              checked={form.values.acceptCommunication}
              onClick={() =>
                form.handleSetValue(
                  "acceptCommunication",
                  !form.values.acceptCommunication
                )
              }
            />
            <FormCheckbox
              name="acceptTerms"
              label={
                <PrivacyPolicy
                  preamble={t("privacyPolicy.additionalText", { person: "I" })}
                />
              }
              checked={form.values.acceptTerms}
              required
              onClick={() =>
                form.handleSetValue("acceptTerms", !form.values.acceptTerms)
              }
            />
          </FormOptionsContainer>
          <ActionBox>
            <Button
              disabled={!form.isFormValid}
              type="primary"
              size="normal"
              onClick={form.handleSubmit}
            >
              {t("auth.signUp.signUp")}
            </Button>
          </ActionBox>
          <SignUpContainer>
            {t("auth.signUp.haveAccount")}
            <LinkStyled to="/login">{t("auth.signUp.login")}</LinkStyled>
          </SignUpContainer>
        </Box>
      </Container>
    </>
  );
};

async function submitHubSpotForm(body) {
  if (import.meta.env.VITE_DEPLOY_ENV !== "production") return;

  const portalId = "24277316";
  const formId = "40da3be7-f026-4a69-8b11-32e13e25cdd4";
  const acceptCommunicationText =
    "I would like to receive additional communication about Click Maint's services";
  const acceptTermsText =
    "By Creating Account, I agree to the Click Maint Terms of Service and Privacy Policy";

  const ipRes = await fetch("https://ipapi.co/json/")
    .then((res) => res.json())
    .catch();
  const ipAddress = ipRes?.ip;
  const hutk = document.cookie.match(/hubspotutk=(\S*);/)?.[1];

  const res = await fetch(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fields: [
          { name: "email", value: body.email },
          { name: "firstname", value: body.name },
          { name: "lastname", value: body.surname },
          { name: "company", value: body.company },
        ],
        legalConsentOptions: {
          consent: {
            consentToProcess: body.acceptTerms,
            text: acceptTermsText,
            communications: [
              {
                value: body.acceptCommunication,
                subscriptionTypeId: 81152333,
                text: acceptCommunicationText,
              },
            ],
          },
        },
        context: {
          pageUri: document.location.href,
          hutk,
          ipAddress,
        },
      }),
    }
  );

  if (!res.ok) {
    let errorData;
    try {
      errorData = await res.json();
    } catch (e) {}

    Sentry.withScope((scope) => {
      scope.setExtras(errorData);
      Sentry.captureException("HubSpot form submission failed");
    });
  }
}

SignUpForm.propTypes = {};
