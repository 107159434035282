import React, { useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Input } from "../Input";
import { TextLink } from "../TextLink";
import { useDebounce } from "../../lib/hooks";
import { useTranslation } from "react-i18next";
import { LabeledQrCode } from "../QrCode";

export const QrCodeInput = ({
  value = "",
  name,
  onChange,
  onBlur,
  setValue,
  generateQr,
  setGenerateQr,
  errorMessage,
  validateField = () => {},
  onTouch = () => {},
}) => {
  const { t } = useTranslation();
  const debouncedValue = useDebounce(value, 500);

  const previousValue = useRef("");

  const orClickHandler = async () => {
    if (!generateQr) {
      previousValue.current = value;
    }

    setGenerateQr(!generateQr);
    await setValue(name, generateQr ? previousValue.current : "");
    onTouch(name);
    validateField(name);
  };

  return (
    <>
      <Input
        name={name}
        placeholder={
          generateQr ? t("qrCodeInput.generated") : t("qrCodeInput.enter")
        }
        value={generateQr || !value ? "" : value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={generateQr}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Or>
        or{" "}
        <TextLink as="button" onClick={orClickHandler}>
          {generateQr
            ? t("qrCodeInput.inputManually")
            : t("qrCodeInput.generateCode")}
        </TextLink>
      </Or>
      {debouncedValue && !generateQr && (
        <LabeledQrCodeStyled data={debouncedValue} />
      )}
    </>
  );
};

QrCodeInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string,
};

const Span = styled.span`
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
`;

const ErrorMessage = styled(Span)`
  margin-top: 6px;
  color: var(--color_alert);
`;

const Or = styled(Span)`
  margin-top: 10px;
  color: var(--color_grey500);
`;

const LabeledQrCodeStyled = styled(LabeledQrCode)`
  width: fit-content;
  margin-top: 15px;
`;
