/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useEffect } from "react";

import {
  getInitialValues,
  getUserFormatForService,
  configureRoles,
} from "modules/users/lib/helpers/form";
import {
  useGetUserQuery,
  useLazyGetUsersRolesQuery,
  useUpdateUserDetailsMutation,
} from "modules/users/state/usersApi";

import { useTranslation } from "react-i18next";
import { useForm } from "shared/lib/hooks/useForm";
import { skipToken } from "@reduxjs/toolkit/query";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { InvitedUserEditor, UserDetailsEditor } from "../UserEditors";

import {
  inviteUserEditValidationSchema,
  userDetailsValidationSchema,
} from "modules/users/config/validationSchema";

import { useLazyGetEntitiesQuery } from "modules/entities/state/entitiesApi";

import { EditorModal } from "shared/components/Modals";

export const UserDetailsEdit = ({
  isShow,
  onClose,
  onOpen,
  type = "user",
  userId,
}) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const { data } = useGetUserQuery(userId ?? skipToken);

  const isAuthenticatedUser =
    data?.status === "active" ||
    data?.status === "inactive" ||
    data?.status === "change_email_in_progress";

  const [trigger, { data: entities }] = useLazyGetEntitiesQuery();
  const [triggerRoles, { data: userRoles }] = useLazyGetUsersRolesQuery();

  useEffect(() => {
    if (isShow) {
      trigger();
      triggerRoles();
    }
  }, [isShow, trigger, triggerRoles]);

  const [updateUserDetails, updateUserDetailsRes] =
    useUpdateUserDetailsMutation();

  const noAccessRoleId = userRoles?.data?.find(
    ({ name }) => name === "no_access"
  )?.id;

  const userDetailsDataForForm = useMemo(
    () =>
      getInitialValues({
        type: "edit",
        data,
        entities,
        noAccessRoleId,
        userType: type,
        t,
      }),
    [data, entities, userRoles]
  );

  const roleOptions = useMemo(
    () => configureRoles(userRoles, t),
    [userRoles, t]
  );

  const form = useForm({
    initialValues: userDetailsDataForForm,
    validationSchema: isAuthenticatedUser
      ? userDetailsValidationSchema(t, noAccessRoleId)
      : inviteUserEditValidationSchema(t, noAccessRoleId),
    willUpdatedValues: userDetailsDataForForm,
    enableReinitialize: true,
    onSubmit: async (body, { setFieldError }) => {
      const user = getUserFormatForService(body, entities);

      updateUserDetails({ id: userId, body: user })
        .unwrap()
        .then(() => {
          onOpen(userId);
        })
        .catch(({ message }) => {
          if (message.email?.[0] === "has already been taken") {
            setFieldError("email", t("m112", { ns: "errors" }));
          } else {
            addSnackBar({
              title: t("allUsers.snack.failUpdateTitle"),
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: t("close"),
            });
          }
        });
    },
  });

  const onCloseModal = () => {
    form.resetForm();
    onClose();
  };

  return (
    <EditorModal
      isOpen={isShow}
      label={t("allUsers.editProfile")}
      secondaryButtonLabel={t("discardChanges")}
      onSubmit={form.handleSubmit}
      disabledSubmit={!form.isWasChanged || !form.isFormValid}
      onClose={onCloseModal}
      isSubmitLoading={
        updateUserDetailsRes.isLoading || updateUserDetailsRes.isSuccess
      }
    >
      {isAuthenticatedUser ? (
        <UserDetailsEditor
          form={form}
          roleOptions={roleOptions}
          avatar={data.images[0]?.attachment_urls?.small}
        />
      ) : (
        <InvitedUserEditor
          form={form}
          roleOptions={roleOptions}
          type={"edit"}
        />
      )}
    </EditorModal>
  );
};
