import React from "react";
import PropTypes from "prop-types";
import { Modal } from "./Modal";
import { ModalHeader } from "./components/ModalHeader";
import {
  ArrowContainer,
  BodyWrapper,
  ModalContentWrapper,
} from "./components/Modals.styled";
import { CloseButton } from "./components/CloseModal";
import { ArrowButton } from "./components/FlipModal";
import { ScrollFadeBox } from "../ScrollFadeBox";

export const ViewModal = ({
  isOpen,
  onClose,
  label,
  subHeader,
  children,
  hint,
  flipModal,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContentWrapper>
        <ModalHeader
          label={label}
          action={<CloseButton onClick={onClose} />}
          flipAction={
            flipModal ? (
              <ArrowContainer>
                <ArrowButton onClick={() => {}} />
                <ArrowButton rotate={180} onClick={() => {}} />
              </ArrowContainer>
            ) : null
          }
          subHeader={subHeader}
        />
        {hint && hint}
        <ScrollFadeBox disableTop>
          <BodyWrapper>{children}</BodyWrapper>
        </ScrollFadeBox>
      </ModalContentWrapper>
    </Modal>
  );
};

ViewModal.propTypes = {
  label: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  subHeader: PropTypes.element,
  hint: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};
