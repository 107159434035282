import React from "react";
import { useUpdateRequestsStatusMutation } from "modules/requests";
import { usePermission } from "app/providers/PermissionsProvider";
import { BulkStatus } from "./BulkStatus";

export const BulkActions = ({ requests }) => {
  const [updateRequests] = useUpdateRequestsStatusMutation();

  const { requestsDeclineListPermit, requestsPutOnHoldListPermit } =
    usePermission();

  return (
    <>
      {requestsPutOnHoldListPermit && (
        <BulkStatus
          requests={requests}
          onUpdate={updateRequests}
          status="on_hold"
        />
      )}
      {requestsDeclineListPermit && (
        <BulkStatus
          requests={requests}
          onUpdate={updateRequests}
          status="declined"
        />
      )}
    </>
  );
};
