import React from "react";
import styled from "styled-components";
import { Filters } from "modules/filters/components/Filters";
import { TableSettings } from "widgets/TableSettings";
import ExportIcon from "shared/assets/icon/table/export.svg?react";
import { IconButton } from "shared/components/IconButton";

export const TopBar = ({
  entity,
  columns,
  getOptions,
  getSearch,
  tableSettings,
  setTableSettings,
  onExportClick,
  quickFilter,
  removeQuickFilter,
  filterCount,
  className,
  withExport = true,
  withFilter = true,
  withTabSettingsAndExport = true,
  showToggleFilters,
  initialFilters,
  /** Additional buttons for the right side of the top bar */
  buttons,
}) => {
  return (
    <Box className={className}>
      <Filters
        fields={columns}
        entity={entity}
        quickFilter={quickFilter}
        removeQuickFilter={removeQuickFilter}
        getOptions={getOptions}
        getSearch={getSearch}
        filterCountValue={filterCount}
        withFilter={withFilter}
        showToggleFilters={showToggleFilters}
        initialFilters={initialFilters}
      />

      <IconBox>
        {withTabSettingsAndExport && withExport && (
          <IconButtonStyled variant="primary" onClick={onExportClick}>
            <ExportIcon />
          </IconButtonStyled>
        )}
        {withTabSettingsAndExport && tableSettings && (
          <TableSettings
            perPage={tableSettings.per_page}
            columns={columns}
            setTableSettings={setTableSettings}
          />
        )}
        {buttons}
      </IconBox>
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  padding: 15px 40px 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;
  border-bottom: 1px solid var(--color_grey100);
  box-sizing: border-box;
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const IconButtonStyled = styled(IconButton)`
  height: fit-content;
`;
