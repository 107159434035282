import i18n from "i18next";

export const InitialFilter = {
  field: "",
  operator: "",
  options: [],
  value: "",
  onSearch: () => {},
};

// common
export const OperatorsByType = {
  string: {
    options: [
      { key: "_cont", value: "contains" },
      { key: "_not_cont", value: "does not contain" },
      { key: "_eq", value: "is" },
      { key: "_not_eq", value: "is not" },
      { key: "_not_null", value: "is not empty" },
      { key: "_null", value: "is empty" },
    ],
    defaultOperator: "_cont",
    getDefaultValue: () => "",
  },
  date: {
    options: [
      { key: "_eq", value: "is" },
      { key: "_not_eq", value: "is not" },
      { key: "_gt", value: "is after" },
      { key: "_lt", value: "is before" },
      { key: "_between", value: "is between" },
      { key: "_null", value: "is empty" },
      { key: "_not_null", value: "is not empty" },
    ],
    defaultOperator: "_eq",
    getDefaultValue: () => "",
  },
  enum: {
    options: [
      { key: "_eq", value: "is" },
      { key: "_not_eq", value: "is not" },
      { key: "_in", value: "is any of" },
      { key: "_not_in", value: "is none of" },
      { key: "_not_null", value: "is not empty" },
      { key: "_null", value: "is empty" },
    ],
    defaultOperator: "_eq",
    getDefaultValue: (op) => (["_not_in", "_in"].includes(op) ? [] : ""),
  },
  integer: {
    options: [
      { key: "_eq", value: "=" },
      { key: "_not_eq", value: "≠" },
      { key: "_lt", value: "<" },
      { key: "_gt", value: ">" },
      { key: "_lteq", value: "≤" },
      { key: "_gteq", value: "≥" },
      { key: "_not_null", value: "is not empty" },
      { key: "_blank", value: "is empty" },
    ],
    defaultOperator: "_eq",
    getDefaultValue: () => "",
  },
  select: {
    options: [
      { key: "_is_exactly", value: "is exactly" },
      { key: "_not_in", value: "has none of" },
      { key: "_in", value: "has any of" },
      { key: "_has_all", value: "has all of" },
      { key: "_null", value: "is empty" },
      { key: "_not_null", value: "is not empty" },
    ],
    defaultOperator: "_is_exactly",
    getDefaultValue: (op) =>
      ["_not_in", "_in", "_has_all"].includes(op) ? [] : "",
  },
  boolean: {
    options: [
      { key: "_true", value: "is true" },
      { key: "_false", value: "is false" },
    ],
    defaultOperator: "_true",
    getDefaultValue: () => "",
  },
};

export const FiltersMap = {
  users: {
    full_name: {
      type: "string",
      filterParameter: "full_name",
    },
    email: {
      type: "string",
      filterParameter: "email",
    },

    status: {
      type: "enum",
      filterParameter: "status",
    },

    activity: {
      type: "date",
      filterParameter: "user_activities_action",
    },
  },

  assets: {
    id: {
      type: "integer",
      filterParameter: "id",
    },
    name: {
      type: "string",
      filterParameter: "name",
    },
    status: {
      type: "enum",
      filterParameter: "status",
    },
    description: {
      type: "string",
      filterParameter: "description",
    },
    parent: {
      type: "enum",
      filterParameter: "parent_id",
    },
    qr_code: {
      type: "string",
      filterParameter: "qr_code_value",
    },
    categories: {
      type: "select",
      filterParameter: "categories_id",
    },
    asset_types: {
      type: "select",
      filterParameter: "asset_types_id",
    },
    model: {
      type: "string",
      filterParameter: "model",
    },
    manufacturer: {
      type: "string",
      filterParameter: "manufacturer",
    },
    serial_number: {
      type: "string",
      filterParameter: "serial_number",
    },
    location: {
      type: "enum",
      filterParameter: "location_id",
    },
    purchase_date: {
      type: "date",
      filterParameter: "purchase_date",
    },
    warranty_exp_date: {
      type: "date",
      filterParameter: "warranty_exp_date",
    },
    life_expectancy: {
      type: "integer",
      filterParameter: "life_expectancy",
    },
    purchase_cost: {
      type: "integer",
      filterParameter: "purchase_cost",
    },
    replacement_cost: {
      type: "integer",
      filterParameter: "replacement_cost",
    },
    salvage_value: {
      type: "integer",
      filterParameter: "salvage_value",
    },
    created_by_name: {
      type: "string",
      filterParameter: "created_by_full_name",
    },
    created_at: {
      type: "date",
      filterParameter: "created_at",
    },
    updated_by_name: {
      type: "string",
      filterParameter: "updated_by_full_name",
    },
    updated_at: {
      type: "date",
      filterParameter: "updated_at",
    },
    parts: {
      type: "select",
      filterParameter: "parts_id",
    },
    vendors: {
      type: "select",
      filterParameter: "vendors_id",
    },
    warranty_expiration_notification: {
      type: "enum",
      filterParameter: "warranty_expiration_notification",
    },
    assign_to: {
      type: "select",
      filterParameter: "users_id",
    },
  },
  parts: {
    id: {
      type: "integer",
      filterParameter: "id",
    },
    name: {
      type: "string",
      filterParameter: "name",
    },
    part_type: {
      type: "enum",
      filterParameter: "part_type_id",
    },
    number: {
      type: "string",
      filterParameter: "number",
    },
    on_hand_qty: {
      type: "integer",
      filterParameter: "on_hand_qty",
    },
    min_qty: {
      type: "integer",
      filterParameter: "min_qty",
    },
    desired_qty: {
      type: "integer",
      filterParameter: "desired_qty",
    },
    available_qty: {
      type: "integer",
      filterParameter: "available_qty",
    },
    unit_cost: {
      type: "integer",
      filterParameter: "unit_cost",
    },
    measurement_unit: {
      type: "enum",
      filterParameter: "measurement_unit_id",
    },
    area: {
      type: "string",
      filterParameter: "area",
    },
    description: {
      type: "string",
      filterParameter: "description",
    },
    storeroom: {
      type: "enum",
      filterParameter: "storeroom_id",
    },
    lead_time: {
      type: "integer",
      filterParameter: "lead_time",
    },
    created_by_name: {
      type: "string",
      filterParameter: "created_by_full_name",
    },
    created_at: {
      type: "date",
      filterParameter: "created_at",
    },
    updated_by_name: {
      type: "string",
      filterParameter: "updated_by_full_name",
    },
    updated_at: {
      type: "date",
      filterParameter: "updated_at",
    },
    qr_code: {
      type: "string",
      filterParameter: "qr_code_value",
    },
    vendors: {
      type: "select",
      filterParameter: "vendors_id",
    },
    assets: {
      type: "select",
      filterParameter: "assets_id",
    },
  },
  vendors: {
    name: {
      type: "string",
      filterParameter: "name",
    },
    id: {
      type: "integer",
      filterParameter: "id",
    },
    rating: {
      type: "integer",
      filterParameter: "rating",
    },
    tax_id: {
      type: "string",
      filterParameter: "tax_id",
    },
    description: {
      type: "string",
      filterParameter: "description",
    },
    assign_to_work_orders: {
      type: "boolean",
      filterParameter: "assign_to_work_orders",
    },
    parts_supplier: {
      type: "boolean",
      filterParameter: "parts_supplier",
    },
    categories: {
      type: "select",
      filterParameter: "categories_id",
    },
    parts: {
      type: "select",
      filterParameter: "parts_id",
    },
    locations: {
      type: "select",
      filterParameter: "locations_id",
    },
    assets: {
      type: "select",
      filterParameter: "assets_id",
    },
    address: {
      type: "string",
      filterParameter: "address",
    },
    hourly_rate: {
      type: "integer",
      filterParameter: "hourly_rate",
    },
    created_by_name: {
      type: "string",
      filterParameter: "created_by_full_name",
    },
    created_at: {
      type: "date",
      filterParameter: "created_at",
    },
    updated_by_name: {
      type: "string",
      filterParameter: "updated_by_full_name",
    },
    updated_at: {
      type: "date",
      filterParameter: "updated_at",
    },
    vendor_contacts: {
      type: "select",
      filterParameter: "vendor_contacts_id",
    },
  },
  work_orders: {
    title: {
      type: "string",
      filterParameter: "title",
    },
    id: {
      type: "integer",
      filterParameter: "id",
    },
    type: {
      type: "enum",
      filterParameter: "type",
    },
    asset: {
      type: "enum",
      filterParameter: "asset_id",
    },
    request: {
      type: "integer",
      filterParameter: "work_order_request_id",
    },
    vendors: {
      type: "select",
      filterParameter: "vendors_id",
    },
    due_date: {
      type: "date",
      filterParameter: "due_date",
    },
    location: {
      type: "enum",
      filterParameter: "location_id",
    },
    priority: {
      type: "enum",
      filterParameter: "priority",
    },
    schedule: {
      type: "integer",
      filterParameter: "pm_schedule_id",
    },

    assign_to: {
      type: "select",
      filterParameter: "users_id",
    },

    status: {
      type: "enum",
      filterParameter: "work_order_status_id",
    },
    categories: {
      type: "select",
      filterParameter: "categories_id",
    },
    created_by_name: {
      type: "string",
      filterParameter: "created_by_full_name",
    },
    created_at: {
      type: "date",
      filterParameter: "created_at",
    },
    updated_by_name: {
      type: "string",
      filterParameter: "updated_by_full_name",
    },
    updated_at: {
      type: "date",
      filterParameter: "updated_at",
    },
    description: {
      type: "string",
      filterParameter: "description",
    },
    completed_at: {
      type: "date",
      filterParameter: "completed_at",
    },
  },
  work_order_requests: {
    title: {
      type: "string",
      filterParameter: "title",
    },
    id: {
      type: "integer",
      filterParameter: "id",
    },
    created_by_name: {
      type: "string",
      filterParameter: "created_by_full_name",
    },
    created_at: {
      type: "date",
      filterParameter: "created_at",
    },
    updated_by_name: {
      type: "string",
      filterParameter: "updated_by_full_name",
    },
    updated_at: {
      type: "date",
      filterParameter: "updated_at",
    },
    description: {
      type: "string",
      filterParameter: "description",
    },
    status: {
      type: "enum",
      filterParameter: "status",
    },
    priority: {
      type: "enum",
      filterParameter: "priority",
    },
    location: {
      type: "enum",
      filterParameter: "location_id",
    },
    asset: {
      type: "enum",
      filterParameter: "asset_ids",
    },
    work_order_id: {
      type: "enum",
      filterParameter: "work_order_id",
    },
    categories: {
      type: "select",
      filterParameter: "categories_id",
    },
    vendors: {
      type: "select",
      filterParameter: "vendors_id",
    },
    due_date: {
      type: "date",
      filterParameter: "due_date",
    },
    request_portal_id: {
      type: "enum",
      filterParameter: "request_portal_id",
    },
    assign_to: {
      type: "select",
      filterParameter: "users_id",
    },
    completed_at: {
      type: "date",
      filterParameter: "completed_at",
    },
  },
  preventive_maintenances: {
    title: {
      type: "string",
      filterParameter: "template_work_order_title",
    },
    id: {
      type: "integer",
      filterParameter: "id",
    },
    status: {
      type: "enum",
      filterParameter: "status",
    },
    next_date: {
      type: "date",
      filterParameter: "next_order_date",
    },
    priority: {
      type: "enum",
      filterParameter: "template_work_order_priority",
    },
    categories: {
      type: "select",
      filterParameter: "template_work_order_categories_id",
    },

    assign_to: {
      type: "select",
      filterParameter: "template_work_order_users_id",
    },

    vendors: {
      type: "select",
      filterParameter: "template_work_order_vendors_id",
    },
    asset: {
      type: "enum",
      filterParameter: "template_work_order_asset_id",
    },
    location: {
      type: "enum",
      filterParameter: "template_work_order_location_id",
    },
    description: {
      type: "string",
      filterParameter: "template_work_order_description",
    },
    start_date: {
      type: "date",
      filterParameter: "start_date",
    },
    schedule: {
      type: "enum",
      filterParameter: "schedule",
    },
    time_to_complete: {
      type: "enum",
      filterParameter: "time_to_complete",
    },
    created_by_name: {
      type: "string",
      filterParameter: "created_by_full_name",
    },
    created_at: {
      type: "date",
      filterParameter: "created_at",
    },
    updated_by_name: {
      type: "string",
      filterParameter: "updated_by_full_name",
    },
    updated_at: {
      type: "date",
      filterParameter: "updated_at",
    },
  },
};

export const FilterParentParam = "filter[parent_id_eq]";

export const FilterAssetStatusOptions = [
  { key: "online", value: "Online" },
  { key: "offline", value: "Offline" },
  { key: "not_tracking", value: "Not tracking" },
];

export const FilterWaarantyNotificationOptions = [
  { key: "0", value: i18n.t("assets.notificationOptions.never") },
  {
    key: 1,
    value: i18n.t("assets.notificationOptions.day"),
  },
  {
    key: 7,
    value: i18n.t("assets.notificationOptions.week"),
  },
  {
    key: 30,
    value: i18n.t("assets.notificationOptions.month"),
  },
  {
    key: 90,
    value: i18n.t("assets.notificationOptions.threeMonth"),
  },
];

export const FilterBooleanOperators = [
  "_null",
  "_not_null",
  "_true",
  "_false",
  "_blank",
];
export const FilterNoSearchFields = ["status", "priority"];

export const FilterPriorityOptions = [
  {
    key: "critical",
    value: i18n.t("work_orders.priority.critical"),
  },
  {
    key: "high",
    value: i18n.t("work_orders.priority.high"),
  },
  {
    key: "medium",
    value: i18n.t("work_orders.priority.medium"),
  },
  {
    key: "low",
    value: i18n.t("work_orders.priority.low"),
  },
  { key: "none", value: i18n.t("work_orders.priority.none") },
];

export const FilterProfileOptions = [
  { key: "active", value: i18n.t("allUsers.status.active") },
  {
    key: "inactive",
    value: i18n.t("allUsers.status.inactive"),
  },
  {
    key: "invitation_sent",
    value: i18n.t("allUsers.status.invitation_sent"),
  },
  {
    key: "verification_pending",
    value: i18n.t("allUsers.status.verification_pending"),
  },
  {
    key: "verification_expired",
    value: i18n.t("allUsers.status.verification_expired"),
  },
  {
    key: "invitation_expired",
    value: i18n.t("allUsers.status.invitation_expired"),
  },
];

export const FilterTypeOptions = [
  { key: "reactive", value: i18n.t("work_orders.type.reactive") },
  {
    key: "preventive",
    value: i18n.t("work_orders.type.preventive"),
  },
];

export const FilterStatusOptions = [
  { key: "on_hold", value: i18n.t("work_order_requests.status.on_hold") },
  { key: "pending", value: i18n.t("work_order_requests.status.pending") },
  { key: "approved", value: i18n.t("work_order_requests.status.approved") },
  { key: "declined", value: i18n.t("work_order_requests.status.declined") },
  { key: "completed", value: i18n.t("work_order_requests.status.completed") },
];
