import React from "react";
import { useTranslation } from "react-i18next";
import { usePermission } from "app/providers/PermissionsProvider";
import { BulkQRCodeExport } from "widgets/BulkQRCodeExport";

export const BulkActions = ({ selectionModel }) => {
  const { t } = useTranslation();

  const count = selectionModel.length;

  const { locationsListExportQrCodesPermit } = usePermission();

  return (
    <>
      {locationsListExportQrCodesPermit && (
        <BulkQRCodeExport
          ids={selectionModel}
          selectedLabel={t("exportQRCodes.confirmation.selected", {
            count,
            module: "Locations",
          })}
          type="locations"
          module="Locations"
        />
      )}
    </>
  );
};
