import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditorModal } from "shared/components/Modals";
import { useGetFormFieldsQuery } from "modules/formFields";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { RESPONSE_MESSAGE } from "shared/lib/constants/api";

import { useAddPartMutation } from "modules/parts";

import { partValidationSchema } from "../../configs";
import {
  getPartFormatForService,
  getOptionalFields,
  getInitialValues,
} from "../../lib/helpers";
import { PartEditor } from "../PartEditor";
import { validateURLsAttributesList } from "shared/lib/helpers/validateURLsAttributesList";

export const PartCreate = ({ isShow, onClose, onOpen }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();
  const [addPart, addPartRes] = useAddPartMutation();

  const { data } = useGetFormFieldsQuery({
    type: "parts",
    action_type: "upsert",
  });

  const optionalFields = getOptionalFields(data);

  const [generateQr, setGenerateQr] = useState(false);

  const onCloseModal = () => {
    addPartForm.resetForm();
    onClose();
    setGenerateQr(false);
  };

  const addPartForm = useForm({
    initialValues: getInitialValues({ type: "create" }),
    validationSchema: partValidationSchema(t, optionalFields),
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (body, { setFieldError }) => {
      const urlsValid = validateURLsAttributesList(
        body.urls_attributes ?? [],
        setFieldError
      );
      if (!urlsValid) return;

      const part = getPartFormatForService(body, {
        generateQr,
        type: "create",
      });
      addPart(part)
        .unwrap()
        .then(({ data }) => {
          addSnackBar({
            title: t("parts.snack.successCreationTitle"),
            content: t("parts.snack.successCreationMessage", {
              name: data.name,
            }),
            type: "success",
            primaryButtonLabel: t("parts.snack.view"),
            secondaryButtonLabel: t("close"),
            handlePrimaryButtonClick: () => onOpen(data.id),
          });
          onCloseModal();
        })
        .catch(({ message }) => {
          if (message.name?.[0] === RESPONSE_MESSAGE.already_taken) {
            setFieldError("name", t("m100", { ns: "errors" }));
          } else if (
            message["qr_code.value"]?.[0] === RESPONSE_MESSAGE.already_taken
          ) {
            setFieldError("code", t("m2", { ns: "errors" }));
          } else {
            addSnackBar({
              title: t("parts.snack.failCreationTitle"),
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: t("close"),
            });
            onCloseModal();
          }
        });
    },
  });

  return (
    <EditorModal
      isOpen={isShow}
      label={t("parts.createNew")}
      onClose={onCloseModal}
      onSubmit={addPartForm.handleSubmit}
      disabledSubmit={
        !addPartForm.dirty || !!Object.values(addPartForm.errors).length
      }
      isSubmitLoading={addPartRes.isLoading || addPartRes.isSuccess}
    >
      <PartEditor
        type="create"
        form={addPartForm}
        optionalFields={optionalFields}
        generateQr={generateQr}
        setGenerateQr={setGenerateQr}
      />
    </EditorModal>
  );
};
