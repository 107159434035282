import {
  addDays,
  formatDuration as fnsFormatDuration,
  intervalToDuration,
  Locale,
  parseISO,
} from "date-fns";
import { enUS } from "date-fns/locale";
import { ReportBucket } from "modules/reporting/types/ReportBucket";

const locales = [enUS];
function getLocale(code: string): Locale {
  return locales.find((i) => i.code === code) ?? enUS;
}

/**
 * Passes options to [toLocaleString](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString),
 *  returns "-" if value is null/undefined/NaN
 */
export function formatNumber(
  val: number | undefined | null,
  locale: string,
  options?: Intl.NumberFormatOptions
) {
  if (typeof val !== "number" || !isFinite(val)) {
    return "-";
  }
  return val.toLocaleString(locale, options);
}

export function formatPercent(
  val: number | undefined | null,
  locale: string,
  options?: Intl.NumberFormatOptions
) {
  return formatNumber(val, locale, { ...options, style: "percent" });
}

export function formatCurrency(
  val: number | undefined | null,
  locale: string,
  currency: string,
  options?: Intl.NumberFormatOptions
) {
  return formatNumber(val, locale, { ...options, style: "currency", currency });
}

/** Formats a duration value in a given unit */
export function formatUnitDuration(
  seconds: number | undefined | null,
  unit: "weeks" | "days" | "hours" | "minutes" | "seconds",
  locale: string,
  options?: Intl.NumberFormatOptions
) {
  if (typeof seconds !== "number" || !isFinite(seconds)) {
    return "-";
  }

  const commonOptions: Partial<Intl.NumberFormatOptions> = {
    style: "unit",
    unitDisplay: "long",
    ...options,
  };
  switch (unit) {
    case "seconds":
      return formatNumber(seconds, locale, {
        ...commonOptions,
        unit: "second",
      });
    case "minutes":
      return formatNumber(seconds / 60, locale, {
        ...commonOptions,
        unit: "minute",
      });
    case "hours":
      return formatNumber(seconds / 3600, locale, {
        ...commonOptions,
        unit: "hour",
      });
    case "days":
      return formatNumber(seconds / 86400, locale, {
        ...commonOptions,
        unit: "day",
      });
    case "weeks":
      return formatNumber(seconds / 604800, locale, {
        ...commonOptions,
        unit: "week",
      });
    default:
      return "-";
  }
}

/**
 * Formats a duration value in "#d #h #m #s" format.
 * @param seconds
 * @param locale
 * @param maxSegments - Maximum number of segments to return.
 *
 * @example
 * formatDuration(15, "en-US") -> "15s"
 * formatDuration(999999, 'en-US', 2) -> "11d 13h"
 * formatDuration(999999, 'en-US', 4) -> "11d 13h 46m 39s"
 */
export function formatDuration(
  seconds: number | undefined | null,
  locale: string,
  maxSegments = 2
) {
  if (typeof seconds !== "number" || !isFinite(seconds)) {
    return "-";
  }

  return (
    fnsFormatDuration(intervalToDuration({ start: 0, end: seconds * 1000 }), {
      delimiter: ",",
      locale: getLocale(locale),
    })
      .replace(/ /g, "")
      // Since a later regex takes only the first letter of each unit, convert month/months to month/months o
      // so that months (mo) can be distinguised from minutes (m)
      .replace(/months?/gi, (match) => `${match} o`)
      // Replace any word in the string with the first letter
      .replace(/(\d[a-z])[a-z]*/gi, "$1")
      .replace("m o", "mo")
      // Split into pieces, only return the two largest parts of the duration
      .split(/,/g)
      .slice(0, maxSegments)
      .join(" ")
  );
}

/** Returns a date or date period string for each ReportBucket option */
export function formatReportBucket(
  date: string,
  bucket: ReportBucket,
  locale: string
) {
  const parsed = parseISO(date);

  switch (bucket) {
    case "week":
      const end = addDays(parsed, 6);
      const weekFormat = new Intl.DateTimeFormat(locale, {
        day: "numeric",
        month: "short",
      });
      return `${weekFormat.format(parsed)}–${weekFormat.format(end)}`;
    case "month":
      const monthFormat = new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "short",
      });
      return monthFormat.format(parsed);
    case "year":
      const yearFormat = new Intl.DateTimeFormat(locale, { year: "numeric" });
      return yearFormat.format(parsed);
    default:
      const dayFormat = new Intl.DateTimeFormat(locale, { dateStyle: "short" });
      return dayFormat.format(parsed);
  }
}
