import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const workOrderTimesApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getWorkOrderTimes: builder.query({
      query: (params) => ({
        url: "/work_order_times",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "WorkOrderTime", id: "WorkOrderTimeList" },
        ...getTagList("WorkOrderTime", result?.data),
      ],
    }),
    addWorkOrderTime: builder.mutation({
      query: (body) => ({
        url: "work_order_times",
        method: "POST",
        body,
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: [
        "WorkOrderDetails",
        { type: "WorkOrderTime", id: "WorkOrderTimeList" },
        { type: "Report" },
      ],
    }),
    updateWorkOrderTime: builder.mutation({
      query: ({ id, body }) => ({
        url: `work_order_times/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        "WorkOrderDetails",
        { type: "WorkOrderTime", id },
        { type: "WorkOrderTimeList", id },
        { type: "Report" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    deleteWorkOrderTime: builder.mutation({
      query: (id) => ({
        url: `/work_order_times/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [
        "WorkOrderDetails",
        { type: "WorkOrderTime", id: "WorkOrderTimeList" },
        { type: "Report" },
      ],
    }),
    startWorkOrderTimer: builder.mutation({
      query: (body) => ({
        url: "work_order_times/start_timer",
        method: "POST",
        body,
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: [
        "WorkOrderDetails",
        { type: "WorkOrderTime", id: "WorkOrderTimeList" },
      ],
    }),
    stopWorkOrderTimer: builder.mutation({
      query: (body) => ({
        url: "work_order_times/stop_timer",
        method: "POST",
        body,
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: [
        "WorkOrderDetails",
        { type: "WorkOrder", id: "WorkOrdersList" },
        { type: "WorkOrderTime", id: "WorkOrderTimeList" },
        { type: "Report" },
      ],
    }),
  }),
});

export const {
  useGetWorkOrderTimesQuery,
  useAddWorkOrderTimeMutation,
  useUpdateWorkOrderTimeMutation,
  useDeleteWorkOrderTimeMutation,
  useStartWorkOrderTimerMutation,
  useStopWorkOrderTimerMutation,
} = workOrderTimesApi;
