import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import CheckIcon from "../../assets/checkbox-check.svg?react";
import DashIcon from "../../assets/checkbox-dash.svg?react";

export const Checkbox = ({
  className,
  checked = false,
  partially = false,
  disabled = false,
  onClick,
}) => {
  return (
    <Box
      className={className}
      checked={checked}
      disabled={disabled}
      onClick={onClick}
    >
      {checked && !partially && <CheckIcon />}
      {checked && partially && <DashIcon />}
    </Box>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  partially: PropTypes.bool,
  disabled: PropTypes.bool,
};

const Box = styled.button`
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  color: var(--color_background_light);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0 0 0 1px var(--color_background_light),
      0 0 0 3px var(--color_primary_dark);
  }

  ${({ checked }) =>
    checked
      ? css`
          border-color: var(--color_primary_dark);
          background-color: var(--color_primary_dark);
        `
      : css`
          border-color: var(--color_grey400);
          background-color: var(--color_background_light);
        `};

  ${({ disabled, checked }) =>
    disabled &&
    css`
      color: var(--color_primary_text);
      background-color: var(--color_grey50);
      border-color: ${checked ? "var(--color_grey50)" : "var(--color_grey100)"};
    `}

  &.password {
    border-radius: 50%;
    color: var(--color_primary_text);
    cursor: inherit;
    ${({ checked }) =>
      checked &&
      css`
        color: var(--color_primary_text);
        background-color: ${checked
          ? "var(--color_corp_lime_dark)"
          : "var(--color_grey50)"};

        border-color: ${checked
          ? "var(--color_corp_lime_dark)"
          : "var(--color_grey50)"};
      `};
  }
`;
