import React from "react";
import PropTypes from "prop-types";
import { Input } from "shared/components/Input";

// TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
//import { MultiSelectDropdown } from "shared/components/Dropdowns";

import { propTypesFormik, defaultPropsFormik } from "shared/lib/hooks/useForm";
import { InlineLabel } from "shared/components/widgets";
import { QrCodeInput } from "shared/components/QrCodeInput";
import { Select } from "shared/components/Select";
import { useSearch } from "shared/lib/hooks/useSearch";
import { useGetAllLocationsQuery } from "modules/locations";
import { getLocationsOptions } from "shared/lib/helpers/data";

export const LocationEditor = React.memo(
  ({
    form = defaultPropsFormik,
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // teamsOptions = [],
    // teamsDefaultOptions = [],
    // onSearchTeams = () => null,
    generateQr,
    setGenerateQr,
    locationId,
    optionalFields,
    defaultLocations,
  }) => {
    const [locations = [], onLocationsSearch] = useSearch(
      useGetAllLocationsQuery,
      !defaultLocations,
      {
        ...(locationId
          ? {
              "filter[id_not_eq]": locationId,
              //excluding locations lower in the hierarchy in the same location family
              "filter[ancestry_does_not_match]": `%25/${locationId}/%25`,
            }
          : {}),
      }
    );

    const parentOptions = getLocationsOptions(
      defaultLocations || locations,
      {},
      "path"
    );

    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // const teamDefaultOptions =
    //   teamsDefaultOptions?.map((t) => ({ key: t.id, value: t.name })) || [];

    // const teamOptions = teamsOptions.map((t) => ({ key: t.id, value: t.name }));

    return (
      <>
        <Input
          name="name"
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder="Location Name"
          errorMessage={form.touched.name && form.errors.name}
          height={50}
          fontSize={20}
        />
        {optionalFields.hasOwnProperty("address") && (
          <InlineLabel
            label="Address"
            isRequired={optionalFields.address.required}
          >
            <Input
              name="address"
              value={form.values.address}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              placeholder="Address"
              errorMessage={form.touched.address && form.errors.address}
            />
          </InlineLabel>
        )}
        {optionalFields.hasOwnProperty("description") && (
          <InlineLabel
            label="Description"
            isRequired={optionalFields.description.required}
          >
            <Input
              name="description"
              value={form.values.description}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              placeholder="Description"
              errorMessage={form.touched.description && form.errors.description}
              isTextArea
            />
          </InlineLabel>
        )}

        {/*// TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
          <InlineLabel label="Teams in charge">
          <MultiSelectDropdown
            name="teams_ids"
            value={form.values.teams_ids}
            options={teamOptions}
            defaultOptions={teamDefaultOptions}
            setValue={form.handleSetValue}
            onBlur={form.handleBlur}
            onSearch={onSearchTeams}
            placeholder="Start typing"
          />
        </InlineLabel> */}
        {optionalFields.hasOwnProperty("qr_code") && (
          <InlineLabel multiline label="QR Code">
            <QrCodeInput
              name="code"
              value={form.values.code}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              setValue={form.handleSetValue}
              generateQr={generateQr}
              setGenerateQr={setGenerateQr}
              errorMessage={form.touched.code && form.errors.code}
            />
          </InlineLabel>
        )}

        {optionalFields.hasOwnProperty("parent") && (
          <InlineLabel
            label="Parent Location"
            isRequired={optionalFields.parent.required}
          >
            <Select
              options={parentOptions}
              name="parent"
              value={form.values.parent}
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              errorMessage={form.touched.parent && form.errors.parent}
              onSearch={onLocationsSearch}
            />
          </InlineLabel>
        )}
      </>
    );
  }
);

LocationEditor.propTypes = {
  form: propTypesFormik,
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // teamsOptions: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  //     value: PropTypes.string,
  //   })
  // ),
  locationsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
    })
  ),
};
