import * as Yup from "yup";
import {
  getStringSchema,
  getSelectSchema,
  getDescriptionSchema,
} from "shared/lib/helpers/validation";

export const updateStatusValidationSchema = (
  t,
  bulkAction = false,
  isManual = false
) =>
  Yup.object({
    note: getDescriptionSchema(t),
    status: bulkAction
      ? getSelectSchema(t, { required: true })
      : getStringSchema(t, { required: true }),
    downtime_type: getStringSchema(t, { required: false }),
    ...(isManual
      ? {
          started_at: getStringSchema(t, { required: true }),
          ended_at: getStringSchema(t, { required: true }),
        }
      : {}),
  });
