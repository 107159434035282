import {
  Outlet,
  Route,
  Routes,
  useParams,
  useLocation,
  Navigate,
} from "react-router-dom";
import { isAuthenticated } from "shared/components/Auth";

import { SideBar } from "widgets/SideBar";

import { List as WorkOrdersPage } from "./WorkOrders";
import { List as EntityUsersPage } from "./Users";
import { HomePage } from "./HomePage";

import {
  LoginPage,
  SignUpPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  ConfirmEmailPage,
} from "./Auth";
import { AuthPageLayout } from "modules/auth";

import { List as LocationsPage } from "./Locations";
import { List as AssetsListPage } from "./Assets/List";
import { List as VendorsPage } from "./Vendors";
import { PartsPage } from "./Parts";
import { RequestsPage } from "./Requests";
import { RequestPortalsPage } from "./RequestPortalsPage";
import { PreventiveMaintenancesPage } from "./PreventiveMaintenances";
import { ProfilePage } from "./Profile";
import { ReportListPage } from "./ReportListPage";
import { ReportPage } from "./ReportPage";
import { PortalPage } from "./PortalPage";

import {
  AdminSettings,
  AssetsAdminSettings,
  PartsAdminSettings,
  VendorsAdminSettings,
  RequestsAdminSettings,
  WorkOrdersAdminSettings,
  PartTypes,
  Categories,
  AssetTypes,
  UserPermissionsView,
  Storerooms,
  LocationsAdminSettings,
} from "./AdminSettings";

import NotFoundPage from "./404";

import { DefaultEntity } from "shared/components/DefaultEntity";
import { ErrorBoundary } from "@sentry/react";
import { PageFallback } from "pages/PageFallback";
import { getModalPath } from "widgets/ModalController";
import { GeneralSettings } from "pages/AdminSettings/GeneralSettings";
import { AllUsersList } from "modules/users";
import { AdministrationManageModule } from "widgets/AdministrationManageModule/index.js";

import { ReportKey } from "modules/reporting/types/ReportKey";
import { BacklogByPriorityReport } from "modules/reporting/components/reports/BacklogByPriorityReport";
import { BacklogByStatusReport } from "modules/reporting/components/reports/BacklogByStatus";
import { MaintenanceHistoryByCostReport } from "modules/reporting/components/reports/MaintenanceHistoryByCost";
import { BacklogByAssigneeReport } from "modules/reporting/components/reports/BacklogByAssignee";
import { OntimeVsOverdueReport } from "modules/reporting/components/reports/OntimeVsOverdue";
import { ReactiveVsPreventiveReport } from "modules/reporting/components/reports/ReactiveVsPreventive";
import { CreatedVsCompletedReport } from "modules/reporting/components/reports/CreatedVsCompleted";
import { AssetCostsAndDowntimeReport } from "modules/reporting/components/reports/AssetCostsAndDowntimeReport";

/** Redirects unauthenticated users to the login page */
const PrivateRouteWrapper = ({ children }) => {
  if (isAuthenticated()) {
    return children;
  }
  return <Navigate to="/login" />;
};

/** Redirects authenticated users away from auth-related routes */
const AuthRouteWrapper = ({ children }) => {
  if (isAuthenticated()) {
    return <Navigate to="/" />;
  }
  return children;
};

const Routing = () => {
  return (
    <Routes>
      <Route path="/portal/:id" element={<PortalPage />} />

      {/* Auth routes - pages for users that aren't signed in */}
      <Route
        element={
          <AuthRouteWrapper>
            <AuthPageLayout>
              <Outlet />
            </AuthPageLayout>
          </AuthRouteWrapper>
        }
      >
        <Route path="/login" element={<LoginPage />}>
          <Route index />
          <Route path="session-close" />
          <Route path="permission-denied" />
        </Route>
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/forgot" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/confirm-email" element={<ConfirmEmailPage />} />
      </Route>

      {/* Private routes - routes for authenicated users */}
      <Route
        path="/"
        element={
          <div id="app">
            <SideBar />
            <DefaultEntity />

            <ErrorBoundary
              fallback={({ resetError }) => (
                <PageFallback resetError={resetError} />
              )}
            >
              <PrivateRouteWrapper>
                <Outlet />
              </PrivateRouteWrapper>
            </ErrorBoundary>
          </div>
        }
      >
        <Route index element={<HomePage />} />
        <Route path="/profile" element={<ProfilePage />} />

        {/***** Redirects for users landing on an object modal from an email link *****/}
        <Route path="/requests/:id" element={<ModalRedirect />} />
        <Route path="/work-orders/:id" element={<ModalRedirect />} />
        <Route
          path="/preventive-maintenances/:id"
          element={<ModalRedirect />}
        />
        <Route path="/assets/:id" element={<ModalRedirect />} />
        <Route path="/locations/:id" element={<ModalRedirect />} />
        <Route path="/parts/:id" element={<ModalRedirect />} />
        <Route path="/vendors/:id" element={<ModalRedirect />} />
        <Route path="/users/:id" element={<ModalRedirect />} />

        {/***** Main pages *****/}
        <Route path="/work-orders" element={<WorkOrdersPage />} />
        <Route path="/assets" element={<AssetsListPage />} />
        <Route path="/locations" element={<LocationsPage />} />
        <Route path="/parts" element={<PartsPage />} />
        <Route path="/vendors" element={<VendorsPage />} />
        <Route path="/users" element={<EntityUsersPage />} />
        <Route path="/requests" element={<RequestsPage />} />
        <Route
          path="/preventive-maintenances"
          element={<PreventiveMaintenancesPage />}
        />
        <Route path="/reporting">
          <Route index element={<ReportListPage />} />
          <Route element={<ReportPage />}>
            <Route
              path={ReportKey.BacklogByAssignee}
              element={<BacklogByAssigneeReport />}
            />
            <Route
              path={ReportKey.BacklogByPriority}
              element={<BacklogByPriorityReport />}
            />
            <Route
              path={ReportKey.BacklogByStatus}
              element={<BacklogByStatusReport />}
            />
            <Route
              path={ReportKey.HistoryByCost}
              element={<MaintenanceHistoryByCostReport />}
            />
            <Route
              path={ReportKey.OntimeVsOverdue}
              element={<OntimeVsOverdueReport />}
            />
            <Route
              path={ReportKey.ReactiveVsPreventive}
              element={<ReactiveVsPreventiveReport />}
            />
            <Route
              path={ReportKey.CreatedVsCompleted}
              element={<CreatedVsCompletedReport />}
            />
            <Route
              path={ReportKey.AssetCostsAndDowntime}
              element={<AssetCostsAndDowntimeReport />}
            />
          </Route>
        </Route>

        {/** Admin tabs – these are `admin-settings` routes where the tabs are visible */}
        <Route path="/admin-settings" element={<AdminSettings />}>
          <Route path="general/*" element={<GeneralSettings />} />
          <Route
            path="manage-modules"
            element={<AdministrationManageModule />}
          />
          <Route path="all-users" element={<AllUsersList />} />
        </Route>

        {/** Admin pages – these are full page `admin-settings` routes */}
        <Route path="/admin-settings">
          <Route path="manage-modules">
            <Route path="storerooms/*" element={<Storerooms />} />
            <Route path="part-types/*" element={<PartTypes />} />
            <Route path="categories/*" element={<Categories />} />
            <Route path="asset-types/*" element={<AssetTypes />} />

            <Route path="assets" element={<AssetsAdminSettings />} />
            <Route path="vendors" element={<VendorsAdminSettings />} />
            <Route path="requests" element={<RequestsAdminSettings />} />
            <Route
              path="inventory-and-parts"
              element={<PartsAdminSettings />}
            />
            <Route path="work-orders" element={<WorkOrdersAdminSettings />} />
            <Route path="locations" element={<LocationsAdminSettings />} />
            <Route path="request-portals" element={<RequestPortalsPage />} />
          </Route>
          <Route path="permissions/view" element={<UserPermissionsView />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default Routing;

/**
 * Redirects from "page/id" routes to ModalController routes.
 * This is required for users opening the app from an email.
 */
const ModalRedirect = () => {
  const location = useLocation();
  const { id } = useParams();
  const page = location.pathname.split("/")[1];

  const type = {
    requests: "request",
    "work-orders": "wo",
    "preventive-maintenances": "pm",
    assets: "asset",
    locations: "location",
    parts: "part",
    vendors: "vendor",
    users: "entityUser",
  }[page];

  if (type) {
    return (
      <Navigate
        to={getModalPath({ search: "", pathname: "/" + page }, type, id)}
        replace
      />
    );
  }
  return null;
};
