import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useSearch,
  usePageParam,
  useSortParam,
  useFiltersParam,
  useQuickFilterParam,
} from "shared/lib/hooks";
import { Table } from "shared/components/Table";
import { useGetLocationsQuery } from "modules/locations";
import { useGetTechniciansQuery } from "modules/users";
import { useGetAssetsQuery } from "modules/assets";
import { useGetAssetTypesQuery } from "modules/assetTypes";
import { useGetVendorsQuery } from "modules/vendors";
import { useGetPartsQuery } from "modules/parts";
import { TopBar } from "widgets/TopBar";
import { BulkActions } from "./BulkActions";
import { useAssetsTableTools } from "modules/assets/lib/helpers/useAssetsTableTools";
import {
  FilterAssetStatusOptions,
  FilterParentParam,
  FilterWaarantyNotificationOptions,
} from "modules/filters/configs/constants";
import { RowDecoratorImage } from "shared/components/Table/components/RowDecoratorImage";
import { useSearchParam } from "shared/lib/hooks";
import { Pagination } from "features/Pagination/index.jsx";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters.js";
import { useIsLoading } from "shared/lib/hooks/useIsLoading";

export const List = ({
  onOpenAsset,
  onExportClick,
  emptyListNode,
  withExport,
}) => {
  const [search] = useSearchParam();
  const [page] = usePageParam(1);
  const [sort] = useSortParam();
  const [filters] = useFiltersParam([]);
  const [quickFilter, setQuickFilter] = useQuickFilterParam();

  const onFilterByParentAsset = ({ id, name }) => {
    setQuickFilter({
      value: id,
      name,
      type: "asset",
    });
  };

  const removeQuickFilter = () => {
    setQuickFilter(null);
  };

  const { columns, tableSettings, applySettings, areTableSettingsFetching } =
    useAssetsTableTools({
      onOpenAsset,
      onFilterByParentAsset,
    });

  const [selectionModel, setSelectionModel] = useState([]);

  const { data, error, isFetching } = useGetAssetsQuery({
    page,
    sort,
    search,
    per_page: tableSettings?.per_page,
    ...stringifyFilters(filters, "assets"),
    [FilterParentParam]: quickFilter?.value,
  });
  const isLoading = useIsLoading(isFetching, [search, page, sort, filters]);

  useEffect(() => {
    if (data) {
      setSelectionModel([]);
    }
  }, [data]);

  const hasFilter = Object.keys(filters).length > 0;
  const notFound =
    data?.data?.length === 0 &&
    !isFetching &&
    (search?.length > 0 || hasFilter);
  const empty = data?.data?.length === 0 && !isFetching && !hasFilter;

  const [locations, onLocationsSearch] = useSearch(useGetLocationsQuery, true);
  const [assets, onAssetsSearch] = useSearch(useGetAssetsQuery, true);
  const [assetTypes, onAssetTypesSearch] = useSearch(
    useGetAssetTypesQuery,
    true
  );
  const [vendors, onVendorsSearch] = useSearch(useGetVendorsQuery, true);
  const [parts, onPartsSearch] = useSearch(useGetPartsQuery, true);
  const [technicians, onTechniciansSearch] = useSearch(
    useGetTechniciansQuery,
    true,
    {},
    "full_name"
  );

  const getFilterOptions = (field) => {
    if (field === "location") {
      return locations?.data.map(({ id, path }) => ({ key: id, value: path }));
    }
    if (field === "parent") {
      return assets?.data.map(({ id, name }) => ({ key: id, value: name }));
    }

    if (field === "asset_types") {
      return assetTypes?.data.map(({ id, name }) => ({ key: id, value: name }));
    }

    if (field === "status") {
      return FilterAssetStatusOptions;
    }

    if (field === "parts") {
      return parts?.data.map(({ id, name }) => ({ key: id, value: name }));
    }

    if (field === "vendors") {
      return vendors?.data.map(({ id, name }) => ({ key: id, value: name }));
    }

    if (field === "warranty_expiration_notification") {
      return FilterWaarantyNotificationOptions;
    }

    if (field === "assign_to") {
      return technicians?.data.reduce((acc, { id, name, surname, status }) => {
        if (status !== "invitation_sent") {
          acc.push({ key: id, value: `${name} ${surname}` });
        }
        return acc;
      }, []);
    }

    return [];
  };

  const getFilterSearch = (field) => {
    if (field === "location") {
      return onLocationsSearch;
    }
    if (field === "parent") {
      return onAssetsSearch;
    }

    if (field === "asset_types") {
      return onAssetTypesSearch;
    }

    if (field === "parts") {
      return onPartsSearch;
    }

    if (field === "vendors") {
      return onVendorsSearch;
    }

    if (field === "assign_to") {
      return onTechniciansSearch;
    }

    return () => {};
  };

  if (empty && !notFound) {
    return emptyListNode ?? null;
  } else if (error) {
    return <div>Error...</div>;
  }

  return (
    <>
      <TopBar
        entity="assets"
        tableSettings={tableSettings}
        setTableSettings={applySettings}
        columns={columns}
        quickFilter={quickFilter}
        removeQuickFilter={removeQuickFilter}
        onExportClick={() => onExportClick({ ...filters, search })}
        getOptions={getFilterOptions}
        getSearch={getFilterSearch}
        withExport={withExport}
      />
      <Table
        columns={columns}
        rows={data?.data}
        highlight={search}
        selectionModel={selectionModel}
        onSelectionModelChange={setSelectionModel}
        showSkeleton={isLoading || areTableSettingsFetching}
        notFound={notFound}
        entityTranslationKey="assets.asset"
        bulkActionsNode={<BulkActions selectionModel={selectionModel} />}
        search={search}
        getRowIcon={(row) => (
          <RowDecoratorImage src={row.images?.[0]?.attachment_urls?.small} />
        )}
      />
      <Pagination paginationData={data?.meta.pagination} label="Assets" />
    </>
  );
};

List.propTypes = {
  onOpenAsset: PropTypes.func,
  emptyListNode: PropTypes.element,
  nothingFoundNode: PropTypes.element,
};
