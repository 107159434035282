import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { ContextualHelp } from "shared/components/ContextualHelp";

import { StockLevel } from "../StockLevel";

export const Stock = ({
  isNonStock,
  unitCost,
  currency,
  unit = "Unit",
  onHand,
  min,
  desired,
  available,
  reserved,
}) => {
  const { t } = useTranslation();
  if (isNonStock) {
    return (
      <StockContainer>
        <StockRow>
          <StockColumn>
            <StockTitle>{t("parts.stock.onHand")}</StockTitle>
            <StockInfo>{t("parts.stock.nonStock")}</StockInfo>
          </StockColumn>
          <StockColumn>
            <UnitCost>
              {t("parts.stock.cost")}
              <ContextualHelp
                content={t("parts.unitCostNonStockDescription")}
              />
            </UnitCost>
            <StockInfo>
              {currency} {unitCost}
            </StockInfo>
          </StockColumn>
        </StockRow>
      </StockContainer>
    );
  }

  const availableValue = Number.parseFloat(available ?? 0).toFixed(0);

  return (
    <StockContainer>
      <StockRow>
        <StockDetails>
          <StockColumn>
            <StockTitle>{t("parts.stock.onHand")}</StockTitle>
            <StockInfo>
              {Number.parseFloat(onHand ?? 0).toFixed(0)} /{" "}
              {Number.parseFloat(desired ?? 0).toFixed(0)} {unit}s
            </StockInfo>
          </StockColumn>
          <StockColumn>
            <StockTitle>{t("parts.stock.available")}</StockTitle>
            <StockInfo color={availableValue <= 0 && "corp_red"}>
              {availableValue} {unit}s
            </StockInfo>
          </StockColumn>
          <StockColumn>
            <StockTitle>{t("parts.stock.reserved")}</StockTitle>
            <StockInfo>
              {Number.parseFloat(reserved ?? 0).toFixed(0)} {unit}s
            </StockInfo>
          </StockColumn>
        </StockDetails>
        <StockColumn>
          <UnitCost>
            {t("parts.stock.cost")}
            <ContextualHelp content={t("parts.unitCostDescription")} />
          </UnitCost>
          <StockInfo>
            {currency} {unitCost}
          </StockInfo>
        </StockColumn>
      </StockRow>
      <StockLevel
        nonStock={isNonStock}
        onHand={onHand}
        min={min}
        desired={desired}
        width="100%"
      />
    </StockContainer>
  );
};

const StockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid var(--color_grey100);
  border-top: 1px solid var(--color_grey100);
  padding: 32px 0;
  margin: 20px 0;
`;

const StockRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StockColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StockDetails = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 46px;
`;

const StockTitle = styled.span`
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  color: var(--color_grey900);
  margin-bottom: 10px;
`;

const StockInfo = styled(StockTitle)`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 0px;
  ${({ color }) =>
    color &&
    css`
      color: ${`var(--color_${color})`};
    `}
`;

const UnitCost = styled(StockTitle)`
  display: flex;
  gap: 4px;
`;
