import React from "react";
import { HeaderContainer, Label, TopHeader } from "./Modals.styled";
import PropTypes from "prop-types";

export const EditorHeader = ({
  label = "Editor",
  action = null,
  variant = "dark",
}) => {
  return (
    <HeaderContainer variant={variant}>
      <TopHeader variant={variant}>
        <Label variant={variant}>{label}</Label>
        {action}
      </TopHeader>
    </HeaderContainer>
  );
};

EditorHeader.propTypes = {
  label: PropTypes.string,
  action: PropTypes.element,
};
