import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "./Dialog";
import { Button } from "../Button";
import PropTypes from "prop-types";
import TrashCanIcon from "shared/assets/icon/trashcan.svg?react";
import {
  ActionBox,
  DeleteBox,
  Container,
  StyledIconButton,
} from "./ConfirmationDialog.styled";

export const ConfirmationDialog = ({
  title,
  description,
  confirmButtonLabel = "Proceed",
  cancelButtonLabel = "Cancel",
  onProceed,
  onCancel,
  onClose,
  onDelete,
  disabledSubmit = false,
  styles,
  disabled,
  isDestructive,
}) => {
  const { t } = useTranslation();

  const isCloseButtonHidden = !!onCancel;

  return (
    <Dialog
      title={title}
      description={description}
      onClose={onClose || onCancel}
      styles={styles}
      actions={
        <Container className="buttons-container">
          {onDelete && (
            <StyledIconButton onClick={onDelete} disabled={disabled}>
              <DeleteBox>
                <TrashCanIcon />
                <span>{t("delete")}</span>
              </DeleteBox>
            </StyledIconButton>
          )}
          <ActionBox>
            {isCloseButtonHidden && (
              <Button variant="secondary" onClick={onCancel}>
                {cancelButtonLabel}
              </Button>
            )}
            <Button
              variant={isDestructive ? "danger" : "primary"}
              onClick={onProceed}
              disabled={disabledSubmit}
            >
              {confirmButtonLabel}
            </Button>
          </ActionBox>
        </Container>
      }
    />
  );
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  confirmButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  onProceed: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  disabledSubmit: PropTypes.bool,
  /** Should be `true` if the action is destructive – makes the confirmation button red */
  isDestructive: PropTypes.bool,
};
