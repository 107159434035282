import { getBaseApiUrl } from "shared/lib/apiTools/getBaseApiUrl";
import { formatDateValue, downloadFile } from "shared/lib/helpers";
import { transformEvents } from "../lib/helpers/api";
import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const workOrdersApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getWorkOrders: builder.query({
      query: (params) => ({
        url: "/work_orders",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "WorkOrder", id: "WorkOrdersList" },
        ...getTagList("WorkOrder", result?.data),
      ],
    }),
    getWorkOrdersKPIs: builder.query({
      query: (params) => ({
        url: "/work_orders/kpis",
        params,
      }),
      providesTags: () => [
        { type: "Entity" },
        { type: "WorkOrder", id: "WorkOrdersKPIs" },
      ],
    }),
    getWorkOrder: builder.query({
      query: (id) => `/work_orders/${id}`,
      providesTags: (result, error, query) => [
        { type: "WorkOrderDetails", id: query },
      ],
      transformResponse: (response) => response.data,
    }),
    addWorkOrder: builder.mutation({
      query: (body) => ({
        url: "work_orders",
        method: "POST",
        body: body,
      }),
      invalidatesTags: [
        { type: "WorkOrder", id: "WorkOrdersList" },
        { type: "WorkOrder", id: "WorkOrdersEvents" },
        { type: "WorkOrder", id: "WorkOrdersKPIs" },
        { type: "PreventiveMaintenance", id: "PreventiveMaintenancesList" },
        { type: "Report" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    exportWorkOrders: builder.mutation({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `work_orders/download_report`,
          method: "POST",
          params,
          responseHandler: (response) => response.blob(),
        });
        const filename = formatDateValue(new Date()) + "-work-orders.xls";
        const url = URL.createObjectURL(result.data);
        downloadFile(url, filename);
        return {};
      },
    }),
    deleteWorkOrder: builder.mutation({
      query: (id) => ({
        url: `/work_orders/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "WorkOrder", id: "WorkOrdersList" },
        { type: "WorkOrder", id: "WorkOrdersEvents" },
        { type: "WorkOrder", id: "WorkOrdersKPIs" },
        { type: "Report" },
      ],
    }),
    deleteWorkOrders: builder.mutation({
      query: (ids) => ({
        url: "/bulk",
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/work_orders/${id}`, false),
            method: "DELETE",
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, ids) => [
        { type: "WorkOrder", id: "WorkOrdersList" },
        { type: "WorkOrder", id: "WorkOrdersEvents" },
        { type: "WorkOrder", id: "WorkOrdersKPIs" },
        { type: "Report" },
        ...ids.map((id) => ({ type: "WorkOrder", id })),
      ],
    }),
    updateWorkOrdersPriority: builder.mutation({
      query: ({ ids, priority }) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/work_orders/${id}`, false),
            method: "put",
            params: {
              priority,
            },
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, { ids }) => [
        { type: "WorkOrder", id: "WorkOrdersList" },
        { type: "WorkOrder", id: "WorkOrdersEvents" },
        { type: "WorkOrder", id: "WorkOrdersKPIs" },
        { type: "Report" },
        ...ids.map((id) => ({ type: "WorkOrder", id })),
      ],
    }),
    getWorkOrderStatuses: builder.query({
      query: (params) => ({
        url: "/work_order_statuses",
        params,
      }),
      providesTags: (result) => [
        { type: "WorkOrderStatus", id: "WorkOrderStatusesList" },
        ...getTagList("WorkOrderStatus", result?.data),
      ],
    }),
    updateWorkOrdersAssignes: builder.mutation({
      // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
      //query: ({ ids, teams_ids, technicians_ids }) => ({
      query: ({ ids, technicians_ids }) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/work_orders/${id}`, false),
            method: "put",
            params: {
              // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
              // teams_ids,
              technicians_ids,
            },
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, { ids }) => [
        { type: "WorkOrder", id: "WorkOrdersList" },
        { type: "WorkOrder", id: "WorkOrdersKPIs" },
        { type: "Report" },
        ...ids.map((id) => ({ type: "WorkOrder", id })),
      ],
    }),
    updateWorkOrdersStatus: builder.mutation({
      query: ({ ids, status_id }) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/work_orders/${id}`, false),
            method: "put",
            params: { status_id },
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, { ids }) => [
        { type: "WorkOrder", id: "WorkOrdersList" },
        { type: "WorkOrder", id: "WorkOrdersEvents" },
        { type: "WorkOrder", id: "WorkOrdersKPIs" },
        { type: "Report" },
        ...ids.map((id) => ({ type: "WorkOrder", id })),
      ],
    }),
    updateWorkOrdersDueDate: builder.mutation({
      query: ({ ids, due_date }) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/work_orders/${id}`, false),
            method: "put",
            params: {
              due_date,
            },
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, { ids }) => [
        { type: "WorkOrder", id: "WorkOrdersList" },
        { type: "WorkOrder", id: "WorkOrdersEvents" },
        { type: "WorkOrder", id: "WorkOrdersKPIs" },
        { type: "Report" },
        ...ids.map((id) => ({ type: "WorkOrder", id })),
      ],
    }),
    updateWorkOrder: builder.mutation({
      query: ({ id, body }) => ({
        url: `work_orders/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "WorkOrder", id },
        { type: "WorkOrderDetails", id },
        { type: "WorkOrder", id: "WorkOrdersEvents" },
        { type: "WorkOrder", id: "WorkOrdersKPIs" },
        { type: "WorkOrderHistory", id: "WorkOrderHistories" },
        { type: "Report" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    printWorkOrders: builder.mutation({
      query: (params) => ({
        url: "/work_orders/print_work_orders",
        method: "POST",
        params,
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getWorkOrdersEvents: builder.query({
      query: (params) => ({
        url: `work_orders/calendar`,
        params,
      }),
      transformResponse: ({
        data,
        meta: {
          entity: { timezone },
        },
      }) => transformEvents(data, timezone),
      providesTags: () => [
        { type: "Entity" },
        { type: "WorkOrder", id: "WorkOrdersEvents" },
      ],
    }),
    getWorkOrdersHistories: builder.query({
      query: (id) => `/work_orders/${id}/changes_history`,
      transformResponse: (response) => response.data,
      providesTags: () => [
        { type: "WorkOrderHistory", id: "WorkOrderHistories" },
      ],
    }),
    addPartTransaction: builder.mutation({
      query: ({ id, body }) => ({
        url: `/work_orders/${id}/add_part_transaction`,
        method: "POST",
        body,
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
      invalidatesTags: (result, error, arg) => [
        { type: "WorkOrder", id: arg.id },
        { type: "WorkOrderDetails", id: arg.id },
        { type: "PartDetails", id: arg.body.part_id },
        { type: "PreventiveMaintenance", id: "PreventiveMaintenanceDetails" },
      ],
    }),
    // PM
    getPMs: builder.query({
      query: (params) => ({
        url: "/pm_schedules",
        params,
      }),
      transformResponse: (res) => {
        if (res.data) {
          res.data = res.data.filter((i) => !!i.template_work_order);
        }
        return res;
      },
      providesTags: (result) => [
        { type: "Entity" },
        {
          type: "PreventiveMaintenance",
          id: "PreventiveMaintenancesList",
        },
        ...getTagList("PreventiveMaintenance", result?.data),
      ],
    }),
    updatePM: builder.mutation({
      query: ({ id, body }) => ({
        url: `pm_schedules/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { id, shouldUpdateList = true }) => [
        ...(shouldUpdateList ? [{ type: "PreventiveMaintenance", id }] : []),
        { type: "PreventiveMaintenanceDetails", id },
        { type: "WorkOrder", id: "WorkOrdersEvents" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    clonePM: builder.mutation({
      query: ({ id, body }) => ({
        url: `pm_schedules/${id}/clone`,
        method: "POST",
        body,
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    exportPMs: builder.mutation({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `pm_schedules/download_report`,
          method: "POST",
          params,
          responseHandler: (response) => response.blob(),
        });
        const filename = formatDateValue(new Date()) + "-pm-schedules.xls";
        const url = URL.createObjectURL(result.data);
        downloadFile(url, filename);
        return {};
      },
    }),
    updatePMsPriority: builder.mutation({
      query: ({ ids, priority }) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/pm_schedules/${id}`, false),
            method: "put",
            params: {
              template_work_order: {
                priority,
              },
            },
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, { ids }) => [
        { type: "WorkOrder", id: "WorkOrdersEvents" },
        { type: "PreventiveMaintenance", id: "PreventiveMaintenancesList" },
        ...ids.map((id) => ({ type: "PreventiveMaintenance", id })),
      ],
    }),
    updatePMsAssignes: builder.mutation({
      // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
      // query: ({ ids, teams_ids, technicians_ids }) => ({
      query: ({ ids, teams_ids }) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/pm_schedules/${id}`, false),
            method: "put",
            params: {
              template_work_order: {
                teams_ids,
                // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
                // technicians_ids,
              },
            },
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, { ids }) => [
        { type: "WorkOrder", id: "WorkOrdersEvents" },
        { type: "PreventiveMaintenance", id: "PreventiveMaintenancesList" },
        ...ids.map((id) => ({ type: "PreventiveMaintenance", id })),
      ],
    }),
    updatePMsStatus: builder.mutation({
      query: ({ ids, status }) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/pm_schedules/${id}`, false),
            method: "put",
            params: {
              status,
            },
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, { ids }) => [
        { type: "WorkOrder", id: "WorkOrdersEvents" },
        { type: "PreventiveMaintenance", id: "PreventiveMaintenancesList" },
        ...ids.map((id) => ({ type: "PreventiveMaintenance", id })),
      ],
    }),
    getPM: builder.query({
      query: (id) => `/pm_schedules/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, query) => [
        { type: "PreventiveMaintenanceDetails", id: query },
        { type: "PreventiveMaintenance", id: "PreventiveMaintenanceDetails" },
      ],
    }),
    deletePM: builder.mutation({
      query: (id) => ({
        url: `/pm_schedules/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [
        { type: "PreventiveMaintenance", id: "PreventiveMaintenancesList" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    printPMs: builder.mutation({
      query: (params) => ({
        url: "/pm_schedules/print_pms",
        method: "POST",
        params,
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    activatePM: builder.mutation({
      query: (body) => ({
        url: `pm_schedules/activate_now`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "PreventiveMaintenance", id },
        { type: "PreventiveMaintenanceDetails", id },
        { type: "WorkOrder", id: "WorkOrdersList" },
        { type: "WorkOrder", id: "WorkOrdersEvents" },
        { type: "Report" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetWorkOrdersQuery,
  useGetWorkOrdersKPIsQuery,
  useGetWorkOrderQuery,
  useAddWorkOrderMutation,
  useDeleteWorkOrderMutation,
  useDeleteWorkOrdersMutation,
  useExportWorkOrdersMutation,
  useUpdateWorkOrdersPriorityMutation,
  useGetWorkOrderStatusesQuery,
  useUpdateWorkOrdersAssignesMutation,
  useUpdateWorkOrdersStatusMutation,
  useUpdateWorkOrdersDueDateMutation,
  useUpdateWorkOrderMutation,
  usePrintWorkOrdersMutation,
  useGetWorkOrdersEventsQuery,
  useAddPartTransactionMutation,
  useGetWorkOrdersHistoriesQuery,
  // PM
  useGetPMsQuery,
  useLazyGetPMsQuery,
  useUpdatePMMutation,
  useExportPMsMutation,
  useUpdatePMsPriorityMutation,
  useUpdatePMsAssignesMutation,
  useUpdatePMsStatusMutation,
  useGetPMQuery,
  useDeletePMMutation,
  usePrintPMsMutation,
  useActivatePMMutation,
  useClonePMMutation,
} = workOrdersApi;
