import styled, { css } from "styled-components";
import { Search } from "./Search";
import PropTypes from "prop-types";
import { H1 } from "shared/components/Typography";
import { Button } from "shared/components/Button";
import Arrow from "shared/assets/icon/arrow.svg?react";

export const Header = ({
  className,
  title,
  searchPlaceholder,
  action,
  withinContainer = false,
  horizontallySpaciousPage = false,
  withSearch = true,
  returnHandler,
}) => {
  return (
    <Box
      className={className}
      withinContainer={withinContainer}
      horizontallySpaciousPage={horizontallySpaciousPage}
    >
      <Segment>
        {returnHandler && (
          <ButtonStyled onClick={returnHandler} variant="secondary">
            <Arrow />
          </ButtonStyled>
        )}
        <Title>{title}</Title>
      </Segment>
      {withSearch && (
        <Segment style={{ justifyContent: "center" }}>
          {searchPlaceholder && <Search placeholder={searchPlaceholder} />}
        </Segment>
      )}
      <Segment>{action && <Buttons>{action}</Buttons>}</Segment>
    </Box>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
  action: PropTypes.element,
  withinContainer: PropTypes.bool,
  horizontallySpaciousPage: PropTypes.bool,
  className: PropTypes.string,
  returnHandler: PropTypes.func,
};

const Box = styled.header`
  position: sticky;
  background: var(--color_header_background);
  height: var(--header-height);
  top: 0;
  right: 0;
  border-bottom: ${({ className }) =>
    className === "border-none"
      ? ""
      : css`1px solid var(--color_header_border)`};
  display: flex;
  align-items: center;
  z-index: 3;
  box-sizing: border-box;

  ${({ withinContainer, horizontallySpaciousPage }) =>
    withinContainer
      ? css`
          width: 100%;
        `
      : css`
          margin-left: 40px;

          ${horizontallySpaciousPage
            ? css`
                width: calc(100% - 40px);
                padding-right: 40px;
              `
            : css`
                width: calc(100% - 80px);
              `}
        `}
`;
const Segment = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  align-items: center;
`;

const Title = styled(H1)`
  font-size: 32px;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: -0.02em;
  color: var(--color_header_title_color);
  margin: 0;
  padding: 0;
`;

const Buttons = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-left: auto;
`;

const ButtonStyled = styled(Button)`
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  width: 32px;
  height: 40px;
  background: var(--color_background_light);
  border: 1px solid var(--color_grey100);
  border-radius: 4px;
`;
