import i18n from "i18next";
import { getAssignToIds } from "shared/lib/helpers";
import { entityZonedTimeToUtc } from "shared/lib/helpers/workWithDate";
import {
  lastDaysOfMonth,
  maxNumbersOptions,
  extraMonthOptions,
  exceptionalMonths,
  extraDayOptions,
} from "../../configs/constants";

export const getOccurrenceOptions = (frequency, scheduleType) => {
  const dropdownOptions = [];
  if (scheduleType === "persistent" && frequency === "month") {
    dropdownOptions.push({ key: "custom", value: "Custom" });
  }

  for (let i = 1; i <= maxNumbersOptions[frequency]; i++) {
    dropdownOptions.push({ key: String(i), value: i });
  }

  if (frequency === "day") {
    extraDayOptions.forEach((v) => dropdownOptions.push({ key: v, value: v }));
  }

  return dropdownOptions;
};

export const getOnMonthOptions = () => {
  const dropdownOptions = [];
  for (let i = 1; i <= 31; i++) {
    const day = exceptionalMonths.includes(i)
      ? i18n.t(`work_orders.form.pm_schedule_attributes.dayOfMonth.${i}`)
      : `${i}th`;
    const value = extraMonthOptions.includes(i)
      ? i18n.t("work_orders.form.pm_schedule_attributes.lastDay", {
          value: day,
        })
      : day;
    dropdownOptions.push({ key: String(i), value });
  }
  return dropdownOptions;
};

export const filterDate = ({
  date,
  repeat_frequency,
  on_week_days,
  repeat_occurrence,
  in_months,
  on_month_day,
  scheduleType,
}) => {
  if (scheduleType === "persistent") {
    if (repeat_frequency === "week") {
      const dayOfWeek = new Date(date).getDay();
      const result = dayOfWeek === 0 ? "7" : String(dayOfWeek);
      return on_week_days?.includes(result);
    }
    if (repeat_frequency === "month") {
      const currentDate = new Date(date);
      const lastDayOfMonth = new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth() + 1,
        0
      );

      const isCorrectDay =
        lastDaysOfMonth.includes(on_month_day) &&
        currentDate.getDate() === lastDayOfMonth.getDate() &&
        String(lastDayOfMonth.getDate()) <= on_month_day
          ? true
          : String(currentDate.getDate()) === on_month_day;

      if (repeat_occurrence === "custom") {
        const monthOrder = String(currentDate.getMonth() + 1);
        return in_months?.includes(monthOrder) && isCorrectDay;
      }
      return isCorrectDay;
    }
  }

  return true;
};

const getMonthsAndWeeksText = (data, type) => {
  const names = data?.map((d) =>
    i18n.t(`work_orders.form.pm_schedule_attributes.${type}.${d}`)
  );
  if (names.length === 1) {
    return `${names[0]}`;
  } else {
    const lastDay = names.pop();
    return `${names.join(", ")} and ${lastDay}`;
  }
};

export const getRepeatsText = ({
  repeat_occurrence,
  repeat_frequency,
  on_month_day,
  on_week_days,
  in_months,
  scheduleType,
}) => {
  let text;
  const occurence = repeat_occurrence !== "1" ? repeat_occurrence : "";
  const frequency =
    repeat_occurrence > 1 ? `${repeat_frequency}s` : repeat_frequency;

  if (scheduleType === "floating") {
    text = i18n.t(
      "work_orders.form.pm_schedule_attributes.information.repeats_text.floating",
      { occurence, frequency }
    );
  } else {
    if (["year", "hour", "day"].includes(repeat_frequency)) {
      text = i18n.t(
        "work_orders.form.pm_schedule_attributes.information.repeats_text.year",
        { occurence, frequency }
      );
    }
    if (repeat_frequency === "week") {
      const weekNames = getMonthsAndWeeksText(on_week_days, "full_week_days");
      text = i18n.t(
        "work_orders.form.pm_schedule_attributes.information.repeats_text.week",
        { occurence, frequency, weekNames }
      );
    }
    if (repeat_frequency === "month") {
      const day = exceptionalMonths.includes(Number(on_month_day))
        ? i18n.t(
            `work_orders.form.pm_schedule_attributes.dayOfMonth.${on_month_day}`
          )
        : `${on_month_day}th`;
      const monthDay = extraMonthOptions.includes(Number(on_month_day))
        ? i18n.t(
            "work_orders.form.pm_schedule_attributes.information.repeats_text.lastDay",
            {
              value: day,
            }
          )
        : day;

      if (repeat_occurrence === "custom") {
        const monthNames = getMonthsAndWeeksText(in_months, "full_months");
        text = i18n.t(
          "work_orders.form.pm_schedule_attributes.information.repeats_text.month_custom",
          { monthNames, monthDay }
        );
      } else
        text = i18n.t(
          "work_orders.form.pm_schedule_attributes.information.repeats_text.month",
          { occurence, frequency, monthDay }
        );
    }
  }
  return text;
};

const addScheduleForFormData = (data, schedule) => {
  data.append("type", schedule.type);
  data.append("time_to_complete", schedule.time_to_complete);
  data.append("start_date", entityZonedTimeToUtc(schedule.start_date));

  if (schedule.type !== "one_time") {
    data.append("repeat_occurrence", schedule.repeat_occurrence);
    data.append("repeat_frequency", schedule.repeat_frequency);
  }

  if (schedule.type === "persistent") {
    if (schedule.repeat_frequency === "week") {
      schedule.on_week_days.forEach((item) => {
        data.append(`on_week_days[]`, item);
      });
    }
    if (schedule.repeat_frequency === "month") {
      data.append("on_month_day", schedule.on_month_day);
      if (schedule.repeat_occurrence === "custom") {
        schedule.in_months.forEach((item) => {
          data.append(`in_months[]`, item);
        });
      }
    }
  }
};

const objectToFormData = (obj) => {
  const formData = new FormData();

  function appendToFormData(data, path = "") {
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        appendToFormData(data[i], `${path}[]`);
      }
    } else if (typeof data === "object") {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          appendToFormData(data[key], path ? `${path}[${key}]` : key);
        }
      }
    } else {
      formData.append(path, data);
    }
  }
  appendToFormData(obj);
  return formData;
};

export const getPMFormatForService = (form) => {
  const {
    uploads_attributes,
    pm_schedule_attributes,
    location,
    asset,
    vendors,
    categories,
    ...otherFields
  } = form;

  const { assign_to, ...workOrderValues } = otherFields;

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const { teams_ids, technicians_ids } = getAssignToIds(assign_to);
  const { technicians_ids } = getAssignToIds(assign_to);

  const data = objectToFormData({
    template_work_order: {
      ...workOrderValues,
      location_id: location?.value || "",
      asset_id: asset?.value || "",
      vendors_ids: vendors.map(({ value }) => value),
      categories_ids: categories.map(({ value }) => value),
      // const { teams_ids, technicians_ids } = getAssignToIds(assign_to);
      // teams_ids,
      technicians_ids,
    },
  });

  uploads_attributes.forEach((fileObj, index) => {
    if (fileObj.isRemove) {
      data.append(
        `template_work_order[uploads_attributes[${index}][remove]]`,
        fileObj.id
      );
    } else if (fileObj.file) {
      data.append(
        `template_work_order[uploads_attributes[${index}][upload]]`,
        fileObj.file
      );
    }
  });

  addScheduleForFormData(data, pm_schedule_attributes);
  return data;
};
