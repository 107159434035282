import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTableSettings } from "modules/fieldConfiguration/lib/useTableSettings";
import { useDisplayDate } from "shared/lib/hooks";
import { Priority } from "shared/components/Priority";

import { Comment } from "shared/components/Comment";
import { Status } from "shared/components/Status";
import { WorkOrderTitle } from "../components/WorkOrderTitle";
import { isWorkOrderOverdue } from "modules/workOrders/lib/helpers/isWorkOrderOverdue";
import { LocationPath } from "shared/components/LocationPath";
import { ModalLinksList } from "shared/components/ModalLinksList";
import { ModalLink } from "shared/components/ModalLink";
import { UserAvatarGroup } from "shared/components/UserAvatarGroup";

export const useWorkOrdersTableTools = ({ onOpen }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const {
    result: { data: tableSettings, isFetching: areTableSettingsFetching },
    applySettings,
  } = useTableSettings("work_order");

  const columns = useMemo(() => {
    const settings = tableSettings?.data.payload;

    return [
      {
        field: "title",
        name: t("work_orders.columns.title"),
        width: 400,
        sortable: true,
        renderCell: (row, value) => (
          <WorkOrderTitle
            onClick={() => onOpen(row.id)}
            value={value}
            startedTimerAt={row.active_timer_current_user?.started_at}
          />
        ),
        primary: true,
        order: 0,
      },
      {
        field: "type",
        name: t("work_orders.columns.type"),
        sortQueryParam: "type",
        width: 200,
        formatValue: (value) => (value ? t(`work_orders.type.${value}`) : null),
        sortable: true,
        order: settings?.type.order,
        hidden: !settings?.type.visible,
        fastFilter: true,
      },
      {
        field: "status",
        name: t("work_orders.columns.status"),
        width: 200,
        sortable: true,
        sortQueryParam: "status_name",
        renderCell: ({ status }) => <Status value={status?.name} />,
        order: settings?.status?.order,
        hidden: !settings?.status.visible,
        fastFilter: true,
      },
      {
        field: "priority",
        name: t("work_orders.columns.priority"),
        width: 150,
        sortable: true,
        renderCell: ({ priority }) => <Priority value={priority} />,
        order: settings?.priority.order,
        hidden: !settings?.priority.visible,
        fastFilter: true,
        initialSort: "desc",
      },
      {
        field: "due_date",
        name: t("work_orders.columns.dueDate"),
        width: 200,
        sortable: true,
        renderCell: ({ due_date, status }) => (
          <span
            style={{
              color: isWorkOrderOverdue(due_date, status?.name)
                ? "#B35B5E"
                : undefined,
            }}
          >
            {displayDate(due_date, true)}
          </span>
        ),
        order: settings?.due_date?.order,
        hidden: !settings?.due_date?.visible,
      },
      {
        field: "assign_to",
        name: t("work_orders.columns.assignedTo"),
        width: 200,
        sortable: true,
        sortQueryParam: "assign_to_name",
        renderCell: (row) => (
          <UserAvatarGroup users={row.technicians} max={4} compact />
        ),
        order: settings?.assign_to.order,
        hidden: !settings?.assign_to.visible,
        fastFilter: true,
      },
      {
        field: "categories",
        sortQueryParam: "categories_name",
        name: t("work_orders.columns.categories"),
        width: 200,
        sortable: true,
        formatValue: (raw) => raw.map(({ name }) => name).join(", "),
        hidden: !settings?.categories?.visible,
        order: settings?.categories?.order,
        fastFilter: true,
      },
      {
        field: "location",
        sortQueryParam: "location_name",
        name: t("work_orders.columns.location"),
        width: 340,
        sortable: true,
        renderCell: (row) => <LocationPath location={row.location} />,
        hidden: !settings?.location?.visible,
        order: settings?.location?.order,
      },
      {
        field: "asset",
        sortQueryParam: "asset_name",
        name: t("work_orders.columns.asset"),
        width: 200,
        sortable: true,
        formatValue: (asset) =>
          asset ? (
            <ModalLink modalParams={{ type: "asset", id: asset.id }}>
              {asset.name}
            </ModalLink>
          ) : null,
        hidden: !settings?.asset?.visible,
        order: settings?.asset?.order,
      },
      {
        field: "vendors",
        name: t("work_orders.columns.vendors"),
        width: 200,
        sortable: true,
        sortQueryParam: "vendors_name",
        formatValue: (vendors) => (
          <ModalLinksList
            type="vendor"
            items={vendors}
            layout="inline"
            separator=", "
          />
        ),
        order: settings?.vendors.order,
        hidden: !settings?.vendors.visible,
      },
      {
        field: "description",
        name: t("work_orders.columns.description"),
        width: 200,
        sortable: true,
        order: settings?.description.order,
        hidden: !settings?.description.visible,
      },
      {
        field: "schedule",
        sortQueryParam: "pm_schedule_id",
        name: t("work_orders.columns.schedule"),
        width: 200,
        sortable: true,
        renderCell: ({ pm_schedule_id: id }) =>
          id ? (
            <ModalLink modalParams={{ type: "pm", id }}>#{id}</ModalLink>
          ) : null,
        order: settings?.schedule?.order,
        hidden: !settings?.schedule?.visible,
      },
      {
        field: "id",
        name: t("work_orders.columns.id"),
        width: 120,
        sortable: true,
        formatValue: (value) => `#${value}`,
        order: settings?.id.order,
        hidden: !settings?.id.visible,
      },
      {
        field: "request",
        sortQueryParam: "work_order_request_id",
        name: t("work_orders.columns.request"),
        width: 200,
        sortable: true,
        renderCell: ({ work_order_request_id: id }) =>
          id ? (
            <ModalLink modalParams={{ type: "request", id }}>#{id}</ModalLink>
          ) : null,
        order: settings?.request?.order,
        hidden: !settings?.request?.visible,
      },
      {
        field: "comments",
        name: t("work_orders.columns.comments"),
        width: 200,
        sortable: true,
        filter: false,
        renderCell: ({ comments }) => (
          <Comment value={comments?.length > 0 ? comments?.length : null} />
        ),
        order: settings?.comments?.order,
        hidden: !settings?.comments?.visible,
      },
      {
        field: "created_at",
        name: t("work_orders.columns.createdAt"),
        width: 200,
        sortable: true,
        formatValue: (value) => displayDate(value, true),
        order: settings?.created_at.order,
        hidden: !settings?.created_at.visible,
      },
      {
        field: "updated_at",
        name: t("work_orders.columns.updatedAt"),
        width: 200,
        sortable: true,
        formatValue: (value) => displayDate(value, true),
        order: settings?.updated_at.order,
        hidden: !settings?.updated_at.visible,
      },
      {
        field: "created_by_name",
        name: t("work_orders.columns.createdBy"),
        width: 150,
        sortable: true,
        formatValue: (value) =>
          value && `${value.name || ""} ${value.surname || ""}`,
        order: settings?.created_by_name?.order,
        hidden: !settings?.created_by_name?.visible,
      },
      {
        field: "updated_by_name",
        name: t("work_orders.columns.updatedBy"),
        width: 150,
        sortable: true,
        formatValue: (value) =>
          value && `${value.name || ""} ${value.surname || ""}`,
        order: settings?.updated_by_name?.order,
        hidden: !settings?.updated_by_name?.visible,
      },
      {
        field: "completed_at",
        name: t("work_orders.columns.completedAt"),
        width: 200,
        sortable: true,
        formatValue: (value) => displayDate(value, true),
        order: settings?.completed_at?.order,
        hidden: !settings?.completed_at?.visible,
      },
    ]
      .filter(({ field }) => settings?.[field]?.visible_global !== false)
      .sort((a, b) => {
        return a.order - b.order;
      });
  }, [t, tableSettings?.data.payload, onOpen, displayDate]);

  return {
    columns,
    tableSettings: areTableSettingsFetching ? undefined : tableSettings?.data,
    areTableSettingsFetching,
    applySettings,
  };
};
