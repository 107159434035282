import { useTranslation } from "react-i18next";
import { ViewModal } from "shared/components/Modals";
import { Details } from "widgets/Details";
import { UserDetailsHeader } from "./UserDetailsHeader";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetUserQuery } from "modules/users/state/usersApi";
import { useDisplayDate } from "shared/lib/hooks";
import { getViewRows } from "modules/users/lib/helpers";
import { InvitationHeader } from "./InvitationHeader";
import { UserDetailViewHeader } from "modules/users/components/UserDetailsView/UserDetailViewHeader";

const fields = [
  "status",
  "phone_number",
  "email",
  "can_be_assigned_to_wo",
  "regular_rate",
  "overtime_rate1",
  "overtime_rate2",
  "activity",
  "permissions",
];

export const UserDetailsView = ({
  isShow,
  onClose,
  onEdit,
  type = "user",
  userId,
}) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const { currentData: data, error } = useGetUserQuery(userId ?? skipToken);

  if (!userId) {
    return null;
  }

  if (!data || error) {
    return <div>{t("allUsers.detailsError")}</div>;
  }

  const isAuthenticatedUser =
    data.status === "active" ||
    data.status === "inactive" ||
    data.status === "change_email_in_progress";

  const { visibleRows, currentEntity } = getViewRows(
    fields,
    data,
    t,
    displayDate
  );

  if (isAuthenticatedUser) {
    return (
      <ViewModal
        subHeader={
          <UserDetailsHeader
            user={data}
            onEdit={() => onEdit(userId)}
            type={type}
            currentEntity={currentEntity}
          />
        }
        isOpen={isShow}
        onClose={onClose}
        label={t("allUsers.profile", {
          name: `${data.name} ${data.surname}`,
        })}
      >
        <UserDetailViewHeader user={data} />
        <Details rows={visibleRows} />
      </ViewModal>
    );
  }

  return (
    <ViewModal
      subHeader={<InvitationHeader user={data} onEdit={() => onEdit(userId)} />}
      isOpen={isShow}
      onClose={onClose}
      label={t("allUsers.invitation", {
        email: data.email,
      })}
    >
      <UserDetailViewHeader user={data} />
      <Details rows={visibleRows} />
    </ViewModal>
  );
};
