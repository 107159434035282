import { getBaseApiUrl } from "shared/lib/apiTools/getBaseApiUrl";
import { formatDateValue, downloadFile } from "shared/lib/helpers";
import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const assetsApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAssets: builder.query({
      query: (params) => ({
        url: "/assets",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "Asset", id: "AssetList" },
        ...getTagList("Asset", result?.data),
      ],
    }),
    addAsset: builder.mutation({
      query: (body) => ({
        url: "assets",
        method: "POST",
        body: body,
      }),
      invalidatesTags: [
        { type: "Asset", id: "AssetList" },
        { type: "Asset", id: "AssetListFull" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    getAsset: builder.query({
      query: (id) => `/assets/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, query) => [
        { type: "AssetDetails", id: query },
      ],
    }),
    updateAsset: builder.mutation({
      query: ({ id, body }) => ({
        url: `assets/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Asset", id },
        { type: "AssetDetails", id },
        { type: "Asset", id: "AssetListFull" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    setAssetsLocation: builder.mutation({
      query: ({ ids, locationId }) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/assets/${id}`, false),
            method: "put",
            params: {
              location_id: locationId,
            },
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, { ids }) => [
        { type: "Asset", id: "AssetList" },
        { type: "Asset", id: "AssetListFull" },
        ...ids.map((id) => ({ type: "Asset", id })),
      ],
    }),
    deleteAsset: builder.mutation({
      query: (id) => ({
        url: `/assets/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [
        { type: "Asset", id: "AssetList" },
        { type: "Asset", id: "AssetListFull" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    deleteAssets: builder.mutation({
      query: (ids) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/assets/${id}`, false),
            method: "DELETE",
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, ids) => [
        { type: "Asset", id: "AssetList" },
        { type: "Asset", id: "AssetListFull" },
        ...ids.map((id) => ({ type: "Asset", id })),
      ],
    }),
    cloneAsset: builder.mutation({
      query: ({ id, body }) => ({
        url: `/assets/${id}/clone`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [
        { type: "Asset", id: "AssetList" },
        { type: "Asset", id: "AssetListFull" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    exportAsset: builder.mutation({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `assets/download_report`,
          method: "POST",
          params,
          responseHandler: (response) => response.blob(),
        });
        const filename = formatDateValue(new Date()) + "-assets.xls";
        const url = URL.createObjectURL(result.data);
        downloadFile(url, filename);
        return {};
      },
    }),
    getAllAssets: builder.query({
      query: (params) => ({
        url: "/assets",
        params: { format_resource: "light", per_page: 10000, ...params },
      }),
      providesTags: [
        { type: "Entity" },
        { type: "Asset", id: "AssetListFull" },
      ],
      transformResponse: (response) => response.data,
    }),
    getAssetHierarchicalList: builder.query({
      query: (id) => ({
        url: `/assets/${id}`,
        params: { format_resource: "hierarchy_tree" },
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, query) => [
        { type: "AssetDetails", id: query },
      ],
    }),
    updateAssets: builder.mutation({
      query: ({ ids, body }) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/assets/${id}`, false),
            method: "put",
            params: body,
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, { ids }) => [
        { type: "Asset", id: "AssetList" },
        ...ids.map((id) => ({ type: "Asset", id })),
      ],
    }),
  }),
});

export const {
  useAddAssetMutation,
  useGetAssetsQuery,
  useUpdateAssetMutation,
  useSetAssetsLocationMutation,
  useGetAssetQuery,
  useDeleteAssetMutation,
  useCloneAssetMutation,
  useDeleteAssetsMutation,
  useExportAssetMutation,
  useGetAllAssetsQuery,
  useGetAssetHierarchicalListQuery,
  useUpdateAssetsMutation,
} = assetsApi;
