import React from "react";
import styled from "styled-components";
import { IconButton } from "shared/components/IconButton";
import CloseIcon from "../../../assets/icon/close.svg?react";

export const CloseButton = ({
  variant = "primary",
  background = "dark",
  ...props
}) => {
  return (
    <IconButtonStyled variant={variant} background={background} {...props}>
      <CloseIcon />
    </IconButtonStyled>
  );
};

const IconButtonStyled = styled(IconButton)`
  width: 24px;
  height: 24px;
`;
