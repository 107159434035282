import { times } from "lodash";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { useDonutChartContext } from "modules/reporting/components/charts/DonutChart/DonutChart";

/** Custom legend for the DonutChart */
export const DonutChartLegend = () => {
  const {
    data,
    total,
    isLoading,
    valueFormatter,
    sliceConfigs,
    active,
    setActive,
  } = useDonutChartContext();

  if (isLoading) {
    return <DonutChartLegendSkeleton rows={Object.keys(sliceConfigs).length} />;
  } else if (!data) {
    return null;
  }

  return (
    <Table>
      <tbody>
        {data.map((row, index) => {
          const val = row.value;
          const config = sliceConfigs[row.key] ?? { fill: "#BFBFC3" };

          return (
            <TableRow
              key={row.key}
              color={config.fill}
              isActive={active?.key === row.key}
              onMouseEnter={() => setActive({ ...row, index })}
              onMouseLeave={() => setActive(undefined)}
            >
              <IconCell>
                <IconWrapper>
                  {config.icon ? (
                    <Icon src={config.icon} alt="" />
                  ) : (
                    <Dot color={config.fill} />
                  )}
                </IconWrapper>
                <RowName>{config.label ?? row.key}</RowName>
              </IconCell>
              <td align="right">{valueFormatter(row.value)}</td>
              <td align="right">
                {total && val ? ((val / total) * 100).toFixed(0) + "%" : "0%"}
              </td>
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
};

const DonutChartLegendSkeleton = ({ rows = 3 }) => {
  return (
    <Table>
      <tbody>
        {times(rows, (i) => (
          <tr key={i}>
            <td>
              <Skeleton
                enableAnimation={false}
                style={{ display: "inline-block" }}
                height="1em"
                width="10ch"
              />
            </td>
            <td>
              <Skeleton
                enableAnimation={false}
                style={{ display: "inline-block" }}
                height="1em"
                width="4ch"
              />
            </td>
            <td>
              <Skeleton
                enableAnimation={false}
                style={{ display: "inline-block" }}
                height="1em"
                width="3ch"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: fit-content;
  font-size: 12px;
  line-height: 2;
  border-collapse: collapse;

  td {
    padding: 4px 6px;
    white-space: nowrap;
  }
`;

const TableRow = styled.tr<{ isActive: boolean }>`
  --fallback: #0000000d;
  --radius: 6px;
  position: relative;

  td {
    background-color: var(--fallback);
    background-color: color-mix(
      in srgb,
      ${(p) => p.color ?? "var(--fallback)"},
      transparent 90%
    );
    ${(p) => (p.isActive ? "" : "background-color: unset !important;")}

    &:first-child {
      border-top-left-radius: var(--radius);
      border-bottom-left-radius: var(--radius);
    }
    &:last-child {
      border-top-right-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
  }
`;

const IconCell = styled.td`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const Dot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${(p) => p.color};
`;

const RowName = styled.span`
  font-weight: 600;
  margin-right: 12px;
`;
