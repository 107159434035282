import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Deletion } from "features/Deletion";

import { Button } from "shared/components/Button";
import { HeaderContextMenu } from "shared/components/Modals";
import { usePermission } from "app/providers/PermissionsProvider";

import { useDeleteRequestMutation } from "modules/requests";
import ApproveIcon from "../../assets/icons/approve.svg?react";
import DeclineIcon from "../../assets/icons/decline.svg?react";
import OnHoldIcon from "../../assets/icons/putonhold.svg?react";
import OpenIcon from "../../assets/icons/open.svg?react";

import { RequestStatusUpdate } from "../RequestStatusUpdate";
import { ModalLink } from "shared/components/ModalLink";

export const RequestHeader = ({
  id,
  name,
  onEdit,
  onClose,
  onApprove,
  status,
  createdById,
  woId,
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);

  const [deleteRequest] = useDeleteRequestMutation();

  const {
    canDeleteRequest,
    canUpdateRequest,
    requestApprovePermit,
    requestDeclinePermit,
    requestPutOnHoldPermit,
  } = usePermission();

  const isDeletePermit = canDeleteRequest(status, createdById);
  const isUpdatePermit = canUpdateRequest(status, createdById);

  const closeModal = () => {
    setModal(false);
  };

  return (
    <>
      <>
        {woId && (
          <ModalLink
            type="button"
            variant="secondary"
            modalParams={{ id: woId, type: "wo" }}
            icon={<OpenIcon />}
            style={{ whiteSpace: "nowrap" }}
          >
            {t(`work_order_requests.header.work_order`)}
          </ModalLink>
        )}
        {requestApprovePermit &&
          (status === "pending" || status === "on_hold") && (
            <Button
              variant="primaryDark"
              icon={<ApproveIcon />}
              onClick={() => onApprove(id)}
            >
              {t("work_order_requests.status.approve")}
            </Button>
          )}
        {requestDeclinePermit &&
          (status === "pending" || status === "on_hold") && (
            <ButtonDecline
              variant="primaryDark"
              icon={<DeclineIcon />}
              onClick={() => setModal("declined")}
            >
              {t("work_order_requests.status.decline")}
            </ButtonDecline>
          )}
      </>
      <SubHeader>
        {requestPutOnHoldPermit && status === "pending" ? (
          <Button
            variant="secondary"
            icon={<OnHoldIcon />}
            onClick={() => setModal("on_hold")}
          >
            {t("work_order_requests.status.on_hold")}
          </Button>
        ) : (
          <div></div>
        )}

        <SubHeaderButtonBlock>
          {isUpdatePermit && (
            <Button variant="secondary" onClick={() => onEdit(id)}>
              {t("edit")}
            </Button>
          )}
          {isDeletePermit && (
            <HeaderContextMenu>
              <Deletion
                id={id}
                module={name}
                onSuccess={onClose}
                confirmation={{
                  title: t("work_order_requests.deletionConfirmation.title"),
                  description: t(
                    "work_order_requests.deletionConfirmation.description"
                  ),
                  confirmButtonLabel: t(
                    "work_order_requests.deletionConfirmation.confirm"
                  ),
                }}
                onDelete={deleteRequest}
              />
            </HeaderContextMenu>
          )}
        </SubHeaderButtonBlock>
      </SubHeader>
      {(modal === "on_hold" || modal === "declined") && (
        <RequestStatusUpdate id={id} status={modal} onClose={closeModal} />
      )}
    </>
  );
};

const SubHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const SubHeaderButtonBlock = styled.div`
  display: flex;
  gap: 8px;
`;

const ButtonDecline = styled(Button)`
  background: var(--color_corp_red);
  border-color: var(--color_corp_red);
  &:hover {
    background: var(--color_corp_red);
  }
  &:active {
    color: var(--color_background);
    background: var(--color_corp_red);
    box-shadow: 0px 0px 0px 2px var(--color_corp_red);
    border: 1px solid var(--color_background);
  }
`;
