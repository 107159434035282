import { useTranslation } from "react-i18next";
import { EditorModal } from "shared/components/Modals";
import { useGetFormFieldsQuery } from "modules/formFields";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { useAddRequestMutation } from "modules/requests";

import {
  requestValidationSchema,
  getInitialValues,
  getOptionalFields,
  getRequestFormatForService,
} from "../../lib/helpers/form";

import { RequestEditor } from "../RequestEditor";

export const RequestCreate = ({ isShow, onClose, onOpen }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();
  const [addRequest, addRequestRes] = useAddRequestMutation();

  const { data } = useGetFormFieldsQuery({
    type: "work_order_requests",
    action_type: "upsert",
  });

  const optionalFields = getOptionalFields({ fields: data, type: "create" });

  const onCloseModal = () => {
    addRequestForm.resetForm();
    onClose();
  };

  const addRequestForm = useForm({
    initialValues: getInitialValues({ type: "create" }),
    validationSchema: requestValidationSchema(t, optionalFields),
    enableReinitialize: true,
    onSubmit: async (body, { setFieldError }) => {
      const request = getRequestFormatForService(body);

      addRequest(request)
        .unwrap()
        .then(({ data }) => {
          addSnackBar({
            title: t("work_order_requests.snack.successCreationTitle"),
            content: t("work_order_requests.snack.successCreationMessage", {
              name: data.title,
            }),
            type: "success",
            primaryButtonLabel: t("work_order_requests.snack.view"),
            secondaryButtonLabel: t("close"),
            handlePrimaryButtonClick: () => onOpen(data.id),
          });
          onCloseModal();
        })
        .catch(({ message }) => {
          if (message.name?.[0] === "has already been taken") {
            setFieldError("title", t("m11", { ns: "errors" }));
          } else {
            addSnackBar({
              title: t("work_order_requests.snack.failCreationTitle"),
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: t("close"),
            });
          }
        });
    },
  });

  return (
    <EditorModal
      isOpen={isShow}
      label={t("work_order_requests.createNew")}
      onClose={onCloseModal}
      onSubmit={addRequestForm.handleSubmit}
      disabledSubmit={
        !addRequestForm.dirty || !!Object.values(addRequestForm.errors).length
      }
      isSubmitLoading={addRequestRes.isLoading || addRequestRes.isSuccess}
    >
      <RequestEditor
        type="create"
        form={addRequestForm}
        optionalFields={optionalFields}
      />
    </EditorModal>
  );
};
