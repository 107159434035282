import styled from "styled-components";
import { FileManager } from "modules/fileManager";

export const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
  background: rgba(5, 31, 64, 0.03);
`;

export const Container = styled.div`
  color: var(--color_grey900);
  width: 560px;

  & input,
  textarea {
    color: var(--color_grey900);
  }

  @media (max-width: 750px) {
    & .label {
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
    }
  }
`;

export const Title = styled.div`
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
`;

export const ContactTitle = styled(Title)`
  margin-top: 60px;
`;

export const DesktopPriority = styled.div`
  @media (max-width: 750px) {
    display: none;
  }
`;
export const MobilePriority = styled.div`
  @media (min-width: 751px) {
    display: none;
  }
`;
