import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ThinAddIcon from "shared/assets/icon/thin-add.svg?react";

import {
  allChildrenHaveSubchildren,
  countChildren,
  sortChildren,
} from "../../lib/helpers/hierarchicalList";
import {
  Container,
  Box,
  Title,
  List,
  ListItem,
  Text,
  ArrowStyled,
  ItemTitle,
  AddButton,
  StyledButton,
  HierarchyLink,
} from "./HierarchicalList.styled";

const CHILDREN_HEIGHT = 36;

const renderListItems = (items = [], level = 0, withoutArrow, getItemLink) => {
  const sortedItems = sortChildren(items);

  return sortedItems.map((item) => {
    const verticalLineHeight =
      countChildren(item.children) * CHILDREN_HEIGHT - CHILDREN_HEIGHT / 2;
    const link = getItemLink && getItemLink(item.id);

    return (
      <ListItem
        key={item.id}
        level={level}
        withVerticalLine={item?.children?.length > 0}
        verticalLineHeight={verticalLineHeight}
        childrenHeight={CHILDREN_HEIGHT}
      >
        <>
          <Box current={item.current} height={CHILDREN_HEIGHT}>
            <ItemTitle>
              {!withoutArrow && (
                <ArrowStyled $rotated={item?.children?.length === 0} />
              )}
              {link && !item.current ? (
                <HierarchyLink as="a" to={link}>
                  {item.name}
                </HierarchyLink>
              ) : (
                <Text>{item.name}</Text>
              )}
            </ItemTitle>
          </Box>

          <List>
            {renderListItems(
              item.children,
              level + 1,
              allChildrenHaveSubchildren(item?.children),
              getItemLink
            )}
          </List>
        </>
      </ListItem>
    );
  });
};

export const HierarchicalList = ({
  data,
  error,
  module,
  onClickAdd,
  addPermission = false,
  getItemLink,
}) => {
  const { t } = useTranslation();

  const treeData = useMemo(
    () => renderListItems(data.tree, 0, false, getItemLink),
    [data?.tree, getItemLink]
  );

  if (error) return;

  return (
    <>
      <Container>
        <Title>{t(`${module}.family`)}</Title>
        <List>{treeData}</List>
        {addPermission && (
          <StyledButton variant="secondary" onClick={onClickAdd}>
            <AddButton>
              <ThinAddIcon />
              {t(`${module}.subItem`)}
            </AddButton>
          </StyledButton>
        )}
      </Container>
    </>
  );
};
